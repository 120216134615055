export default {
    'en': {
        'pages': {
            'home': {
                'slides': {
                    'aboutTitle': 'This is Droxic.',
                    'aboutSubtitle': 'We Create Digital Experiences.',
                    'aboutButtonText': 'Explore',
                    'clientsTitle': 'Clients.',
                    'clientsSubtitle': 'Global brands, spirited startups, and fellow agencies.',
                    'clientsButtonText': 'See Clients',
                    'expertiseTitle': 'What we’re about.',
                    'expertiseSubtitle': 'Digital is our playground and passion.',
                    'expertiseButtonText': 'Our Capabilities',
                    'contactsTitle': 'Unlimited Reach.',
                    'contactsSubtitle': 'We’re truly global, with offices and friends everywhere.',
                    'contactsButtonText': 'Contact'
                },
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digital Platforms.',
                        'digitalPlatformsSubtitle': 'We code websites and digital experiences that connect brands with people.',
                        'mobileApplicationsTitle': 'Mobile Applications.',
                        'mobileApplicationsSubtitle': 'We deliver Native & Hybrid Mobile Apps for every major operating system.',
                        'creativeDirectionsTitle': 'Creative Direction.',
                        'creativeDirectionsSubtitle': 'Every project is guided by a clear and creative pathway, unique to our client.',
                        'brandingTitle': 'Branding.',
                        'brandingSubtitle': 'Our code, design, and copywriting builds strong brands using research and strategy.'
                    }
                }
            },
            'about': {
                'description': "We’re a proactive digital agency with a compulsion for precision, a partner for fellow agencies and clients around the world, creating branded experiences in web and mobile.",
                'expertiseTitle': 'Expertise.',
                'expertiseSubtitle': 'Digital: Web & Mobile.',
                'expertiseButtonText': 'What We Do',
                'teamTitle': 'Team.',
                'teamSubtitle': 'Thinkers. Makers. Together.',
                'teamButtonText': 'Who We Are',
                'clientsTitle': 'Clients.',
                'clientsSubtitle': 'We’re nothing without our clients.',
                'clientsButtonText': 'See Clients',
                'referencesTitle': 'Experience.',
                'referencesSubtitle': 'See our success firsthand.',
                'referencesButtonText': 'Our Stories',
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digital Platforms.',
                        'digitalPlatformsSubtitle': 'We code websites and digital experiences that connect brands with people.',
                        'mobileApplicationsTitle': 'Mobile Applications.',
                        'mobileApplicationsSubtitle': 'We deliver Native & Hybrid Mobile Apps for every major operating system.',
                        'creativeDirectionsTitle': 'Creative Direction.',
                        'creativeDirectionsSubtitle': 'Every project is guided by a clear and creative pathway, unique to our client.',
                        'brandingTitle': 'Branding.',
                        'brandingSubtitle': 'Our code, design, and copywriting builds strong brands using research and strategy.'
                    },
                    'team': {
                        'pavelName': 'Pavel',
                        'pavelPosition': 'CEO',

                        'ilianName': 'Ilian',
                        'ilianPosition': 'CTO',

                        'chopyName': 'Chopy',
                        'chopyPosition': 'CPO',

                        'stoyanName': 'Stoyan',
                        'stoyanPosition': 'CPP',

                        'mariName': 'Mari',
                        'mariPosition': 'Business development',

                        'georgeName': 'George',
                        'georgePosition': 'Business development',

                        'sevdaName': 'Sevda',
                        'sevdaPosition': 'Business development',

                        'arminName': 'Armin',
                        'arminPosition': 'Business development',

                        'joostName': 'Joost',
                        'joostPosition': 'Business development',

                        'richardName': 'Richard',
                        'richardPosition': 'Business development',

                        'viliName': 'Vili',
                        'viliPosition': 'Resource Manager',

                        'zoriName': 'Zori',
                        'zoriPosition': 'Account executive',

                        'loraName': 'Lora',
                        'loraPosition': 'HR Manager',

                        'veronikaName': 'Veronika',
                        'veronikaPosition': 'HR Generalist',

                        'ivaName': 'Iva',
                        'ivaPosition': 'HR Generalist',

                        'natName': 'Nat',
                        'natPosition': 'HR Generalist',

                        'borislavaHrName': 'Borislava',
                        'borislavaHrPosition': 'HR Manager',

                        'hristinaName': 'Hristina',
                        'hristinaPosition': 'Head of Marketing',

                        'julianName': 'Julian',
                        'julianPosition': 'Project manager',

                        'valeriaName': 'Valeria',
                        'valeiraPosition': 'Project manager',

                        'alexName': 'Alex',
                        'alexPosition': 'UX/UI',

                        'konstantinName': 'Konstantin',
                        'konstantinPosition': 'Software engineer',

                        'daniName': 'Dani',
                        'daniPosition': 'Software engineer',

                        'galinName': 'Galin',
                        'galinPosition': 'Software engineer',

                        'kirilName': 'Kiril',
                        'kirilPosition': 'Software engineer',

                        'lyuboName': 'Lyubo',
                        'lyuboPosition': 'Software engineer',

                        'emiName': 'Emi',
                        'emiPosition': 'Software engineer',

                        'zdravkoName': 'Zdravko',
                        'zdravkoPosition': 'Software engineer',

                        'radovanName': 'Radovan',
                        'radovanPosition': 'Branch manager',

                        'tsvetoName': 'Tsveto',
                        'tsvetoPosition': 'Software engineer',

                        'krisiName': 'Krisi',
                        'krisiPosition': 'Software engineer',

                        'borislavaName': 'Borislava',
                        'borislavaPosition': 'Software engineer',

                        'ivanvName': 'Ivan V',
                        'ivanvPosition': 'Software engineer',

                        'kristianName': 'Kris',
                        'kristianPosition': 'Software engineer',

                        'slavName': 'Slav',
                        'slavPosition': 'Software engineer',

                        'dimitarName': 'Dimitar',
                        'dimitarPosition': 'Software engineer',

                        'tsvetanName': 'Tsvetan',
                        'tsvetanPosition': 'Software engineer',

                        'dautName': 'Daut',
                        'dautPosition': 'Software engineer',

                        'dimiName': 'Dimi',
                        'dimiPosition': 'QA Specialist',

                        'bojoName': 'Bojo',
                        'bojoPosition': 'QA Specialist',

                        'foriName': 'Fori',
                        'foriPosition': 'QA Specialist',

                        'chochoName': 'Chocho',
                        'chochoPosition': 'QA Specialist',

                        'vladiName': 'Vladi',
                        'vladiPosition': 'Sys Admin',

                        'saraName': 'Sara',
                        'saraPosition': 'Marketing Assistant',

                        'valetoName': 'Valentina',
                        'valetoPosition': 'Office Manager',

                        'kristinaSavovaName': 'Kristina',
                        'kristinaSavovaPosition': 'Office Manager',

                        'dobriName': 'Dobri',
                        'dobriPosition': 'Software engineer',

                        'dusanName': 'Dušan',
                        'dusanPosition': 'Software engineer',

                        'darkoName': 'Darko',
                        'darkoPosition': 'Software engineer',

                        'ivanchName': 'Ivan',
                        'ivanchPosition': 'Software engineer',

                        'dimitardName': 'Dimitar',
                        'dimitardPosition': 'Software engineer',

                        'milenaName': 'Milena',
                        'milenaPosition': 'Office Manager Niš',

                        'danielaName': 'Daniela',
                        'danielaPosition': 'Account Executive Assistant',

                        'ninaName': 'Nina',
                        'ninaPosition': 'Software engineer',

                        'vanyoName': 'Vanyo',
                        'vanyoPosition': 'UX/UI',

                        'petarName': 'Petar',
                        'petarPosition': 'Software engineer',

                        'biljanaName': 'Biljana',
                        'biljanaPosition': 'Software engineer',

                        'miroljubName': 'Miroljub',
                        'miroljubPosition': 'Software engineer',

                        'radosName': 'Radoš',
                        'radosPosition': 'Software engineer',

                        'dejanName': 'Dejan',
                        'dejanPosition': 'QA specialist',

                        'lazaName': 'Laza',
                        'lazaPosition': 'Software engineer',

                        'aleksandarName': 'Aleksandar',
                        'aleksandarPosition': 'Software engineer',

                        'markoName': 'Marko',
                        'markoPosition': 'Software engineer',

                        'yanaName': 'Yana',
                        'yanaPosition': 'Project manager',

                        'boyanName': 'Boyan',
                        'boyanPosition': 'Project manager',

                        'ivanDimitrovName': 'Ivan',
                        'ivanDimitrovPosition': 'Software engineer',

                        'stefanName': 'Stefan',
                        'stefanPosition': 'Software engineer',
                    },
                    'references': {
                        'realityBytesName': 'Andreas  Kuno  Kuntze',
                        'realityBytesPosition': 'CEO',
                        'realityBytesCompany': 'Reality  Bytes  Neue  Medien',
                        'realityBytesDescription': 'Starting  two  years  from  now,  Droxic  team  has  become  our  seamless  extension  for  production.  Our  partnership  has  grown  to  full-stack  development  services.  They  are  always  on  the  cutting  edge  of  technology  innovation.I  can  fully  trust  them.',


                        'lieferheldName': 'Matthias Pfaff',
                        'lieferheldPosition': 'Head of customer experience',
                        'lieferheldCompany': 'Lieferheld',
                        'lieferheldDescription': 'I had developers from Droxic working in my teams for either Android, iOS, Python and JS development. All of their people were very nice, competent and fun to work with. Droxic as a company was always super reliable and provided a lot of flexibility as a partner whom I would and do recommend always and to everybody, who is in need for development support in their projects or products.',


                        'oliverUkName': 'Martin  Nartey',
                        'oliverUkPosition': 'Digital  Project  Lead',
                        'oliverUkCompany': 'Oliver  UK',
                        'oliverUkDescription': "Droxic’s  digital  expertise  is  second  to  none.  Working  with  them  on  a  showcase  digital  project  for  one  of  our  biggest  clients,  they  exceeded  expectations  on  all  the  requirements  we  sent  over  and  they  were  extremely  flexible  and  able  to  plan  around  our  project  needs.  They  helped  bring  organisation  to  a  tricky  project  and  our  client  was  absolutely  delighted  with  the  final  product.",


                        'kweakName': 'Baris  Karatas',
                        'kweakPosition': 'CTO',
                        'kweakCompany': 'Kweak',
                        'kweakDescription': "I  would  like  to  highlight  our  appreciation  for  the  reliable  cooperation  and  the  skills  your  team  brought  to  our  project.  It  was  a  great  journey  and  I  will  definitely  recommend  Droxic  and  it’s  great  team  to  others.  Hope  to  get  in  touch  with  you  guys  in  future  again.",


                        'exposureName': 'Rory Oliver',
                        'exposurePosition': 'Production Director',
                        'exposureCompany': 'Exposure UK',
                        'exposureDescription': 'I worked with Droxic while I was Production Director at Exposure and I can quite honestly say they are the best outsource developer I have ever worked with. We started them off with a mid-sized but extremely high profile project and they over-delivered. Over the next 2 years we worked on a number of projects and each time the quality, service and delivery was second-to-none. I would not hesitate to use them again. They are also great guys and a pleasure to work with.',


                        'icaewName': 'Adam  Thompson',
                        'icaewPosition': 'UX  Manager',
                        'icaewCompany': 'ICAEW',
                        'icaewDescription': "We  started  using  Droxic  to  supplement  our  UK-based  development  resource  and  have  been  impressed  with  both  the  quality  of  their  work  and  their  flexibility  to  scale  up  and  down  the  number  of  developers  working  on  our  projects  at  short  notice",


                        'siiliName': 'Matti Kiviluoto',
                        'siiliPosition': 'CMS Team Lead',
                        'siiliCompany': 'Siili Solutions Plc',
                        'siiliDescription': "We have been extremely pleased with the professionalism and competence of the developers we have worked with and our business relationship has always been smooth, transparent and fair. We have repeatedly been surprised by Droxic’s ability to provide new highly skilled developers in such diverse fields of development. I can sincerely recommend Droxic as a software development partner!",


                        'initName': 'Klaus Santel',
                        'initPosition': 'Director Development',
                        'initCompany': 'Init',
                        'initDescription': "For many years, Droxic has been a reliable partner when it comes to supporting our web projects. They are very flexible and always committed to meeting our expectations. Droxic has helped us in many critical project situations and resource shortages quickly and competently, it is always a pleasure to work with them. The communication is exemplary and in case of problems we always found a solution together very quickly."
                    }
                }
            },
            'careers': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'We want talent.',
                'frontPageDescription': 'Makers everywhere, keep scrolling for the opportunities.',
                'wantToJoinTitle': 'Join us.',
                'applyNowButton': 'Apply',
                'gdprText': 'By applying to this position, I give my consent that the personal data included in my CV/resume/motivational letter will be processed in accordance with Droxic\'s Data Protection Policy and for the mentioned period of time (<a>Data Protection Policy</a>)',

                'jobOffer1': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Sitecore Engineer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as a Sitecore Engineer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Sitecore architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with Sitecore development tools and CI/CD pipelines.',
                    'requiredSkillsListItem4': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem5': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem6': 'Excellent problem-solving skills and attention to details.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Develop, implement, and maintain Sitecore-based web applications.',
                    'responsibilitiesListItem2': 'Collaborate with cross-functional teams to define, design, and ship new features.',
                    'responsibilitiesListItem3': 'Optimize applications for maximum speed and scalability',
                    'responsibilitiesListItem4': 'Troubleshoot and debug existing applications, ensuring a high-performance user experience.',
                    'responsibilitiesListItem5': 'Stay updated with the latest industry trends and best practices related to Sitecore.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer2': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Senior Front-end Expert.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'JavaScript is a children’s’ toy for you.',
                    'requiredSkillsListItem2': 'You have extensive experience in Front-End / Full Stack web development.',
                    'requiredSkillsListItem3': 'You have particular affinity for React.js and/or Vue.js, and have participated in software projects using at least one of these technologies.',
                    'requiredSkillsListItem4': 'You have solid knowledge in CSS3 (including SASS/LESS) and HTML5.',
                    'requiredSkillsListItem5': 'You are always open to expanding your knowledge and can easily pick up on a new framework.',
                    'requiredSkillsListItem6': 'You have proven experience using responsive design principles.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will develop sophisticated visual and functional front-end components.',
                    'responsibilitiesListItem3': 'You will build reusable, scalable code; following best practices.',
                    'responsibilitiesListItem4': 'You will work with some of the hottest technologies around.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer3': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Business Development Manager.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'A few years of progressive selling experience to international clients in the digital field.',
                    'requiredSkillsListItem2': 'Working knowledge of the web and mobile development services landscape.',
                    'requiredSkillsListItem3': 'Existing network of end client relationships in Western Europe.',
                    'requiredSkillsListItem4': 'Self-starter, excellent negotiator and a team player.',
                    'requiredSkillsListItem5': 'Excellent presentation, phone and written communication skills.',
                    'requiredSkillsListItem6': 'Highly proficient in English.',
                    'requiredSkillsListItem7': 'Second language proficiency - Swedish/Norwegian/Danish/Finnish or German.',
                    'requiredSkillsListItem8': 'Demonstrated passion for web and mobile innovations.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Aim for growth in market share, volume, turnover and profit.',
                    'responsibilitiesListItem2': 'Approach new prospects and offer Droxic’s services.',
                    'responsibilitiesListItem3': 'Generate new relationships with prospective clients.',
                    'responsibilitiesListItem4': 'Understand customer needs and highlight the features and the benefits of our quality-oriented services.',
                    'responsibilitiesListItem5': 'Work close with internal software development department and PMs to develop strategies and offers that meet the brief of the client.',
                    'responsibilitiesListItem6': 'Identifying further opportunities to serve the existing clients.',
                    'responsibilitiesListItem7': 'Maintain up to date knowledge on trends, issues and opportunities within territory.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer4': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Java senior developer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have practical experience in JSE based web development technologies.',
                    'requiredSkillsListItem2': 'You have practical experience in Spring & Spring Boot.',
                    'requiredSkillsListItem3': 'You have knowledge in CI/CD methodology / tooling (Maven, Jenkins, GitLab-CI/CD, Bamboo).',
                    'requiredSkillsListItem4': 'You have knowledge in container technologies (Docker, Kubernetes, OpenShift/OKD).',
                    'requiredSkillsListItem5': 'You have knowledge in relational Databases (MySQL, Postgres, Oracle).',
                    'requiredSkillsListItem6': 'Knowledge in requirements engineering / technical specifications / scope management.',
                    'requiredSkillsListItem7': 'You have knowledge in architectural approaches.',
                    'requiredSkillsListItem8': 'You have experience in agile methodologies.',
                    'requiredSkillsListItem9': 'It’s a plus if you have knowledge in architectural approaches.',
                    'requiredSkillsListItem10': 'It’s a plus if you have experience in software testing technologies.',
                    'requiredSkillsListItem11': 'It’s a plus if you have knowledge in customizing Content Management Systems (esp. CoreMedia CMS).',
                    'requiredSkillsListItem12': 'It’s a plus if you have knowledge in further Java frameworks (JEE, Quarkus, Wicket, Camunda, Keycloak, form.io, JSF).',
                    'requiredSkillsListItem13': 'You have good written and spoken language skills (English).',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer5': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Application Support Specialist.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have minimum 3-5 years of experience in technical consulting and analytics.',
                    'requiredSkillsListItem2': 'You have good understanding of the issues that an end-user faces with corporate IT Service Management.',
                    'requiredSkillsListItem3': 'You have analytical approach to recurring problems and collected data.',
                    'requiredSkillsListItem4': 'You have good communication skills and ability to maintain good relationship with customers.',
                    'requiredSkillsListItem5': 'You have positive attitude and great interpersonal skills, with a “can do” attitude.',
                    'requiredSkillsListItem6': 'You have excellent written and spoken English language skills.',
                    'requiredSkillsListItem7': 'It’s an advantage if you have relevant software industry experience in any of the following: IT systems, enterprise or infrastructure management, application development and management, security, and/or analytics.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will assist with general inquiries, product support, and troubleshooting via phone, e-mail, and chat.',
                    'responsibilitiesListItem2': 'You will track customer issues using a ticketing system to ensure quick resolution and proper follow-up and documentation.',
                    'responsibilitiesListItem3': 'You will collect data and draw conclusions from it, identifying problems and suggest resolutions.',
                    'responsibilitiesListItem4': 'You will analyze customer needs, goals, strategic plans and constraint in order to advice the best possible use of the platform.',
                    'responsibilitiesListItem5': 'You will have to understand Customer Digital Experience goals, strategic plans and constraints in order to advise on the best use of the product.',
                    'responsibilitiesListItem6': 'You will stay current on product development/releases to a level required for the above activities.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer6': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Project Manager.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'At least 2-3 years of technical delivery experience with a demonstrated PM track record in web and mobile projects.',
                    'requiredSkillsListItem2': 'Full understanding of how to manage an end-to-end technical  project lifecycle, from idea inception through development and launch.',
                    'requiredSkillsListItem3': 'Demonstrated experience simultaneously working on multiple projects with multiple partners.',
                    'requiredSkillsListItem4': 'Experience with both Waterfall and Agile.',
                    'requiredSkillsListItem5': 'Experience facilitating rapid application development teams to deliver technology solutions on time, on budget, and according to client requirements.',
                    'requiredSkillsListItem6': 'Experience using an agile delivery management platform, JIRA preferred.',
                    'requiredSkillsListItem7': 'Fluent English language.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Directing and coordinating multiple web and mobile projects from initiation phase through to implementation phase.',
                    'responsibilitiesListItem2': 'Translation of business requirements into technology solutions and participation in the analysis, evaluation and choice of development toolchains.',
                    'responsibilitiesListItem3': 'Working closely with cross-functional teams in order to define scope, goals and deliverables in alignment with our clients\' goals and our business strategy.',
                    'responsibilitiesListItem4': 'Project estimations and resource planning.',
                    'responsibilitiesListItem5': 'Tracking and reporting on progress. Aiming for delivery of quality solutions on time.',
                    'responsibilitiesListItem6': 'Proactively managing changes and improvements in project scope, identifying issues and driving for resolution.',
                    'responsibilitiesListItem7': 'Managing relationships with new clients and partners.',
                    'responsibilitiesListItem8': 'Organizing and facilitating of daily stand-ups and retrospectives at the end of each sprint. Creating an environment in which the team is continuously learning and encouraged to reflect and improve their delivery capacity.',
                    'responsibilitiesListItem9': 'Coordinating communication between teams and facilitating productive discussions.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer7': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Data Engineer.',
                    'location': 'Sofia',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have 2+ years of industry data engineering experience, including experience in ETL design, implementation and maintenance.',
                    'requiredSkillsListItem2': 'You have proven experience in the data warehouse space, as well as schema design.',
                    'requiredSkillsListItem3': 'You are very good in SQL and at least one modern programming language (Python, Java, etc).',
                    'requiredSkillsListItem4': 'You have profound knowledge of Kubernetes.',
                    'requiredSkillsListItem5': 'You are experienced in designing data solutions within cloud platforms such as AWS (preferred), Google Cloud Platform or Azure.',
                    'requiredSkillsListItem6': 'You have strong knowledge and experience with some distributed data processing technology like Redshift/BigQuery/Snowflake/Spark or similar.',
                    'requiredSkillsListItem7': 'Bonus: If you have experience with Streaming data AWS Kinesis, Kafka or RabbitMQ.',
                    'requiredSkillsListItem8': 'Bonus: if you have Bachelor’s degree in a technical field (computer science, engineering, mathematics,  informatics); advanced degree preferred (or equivalent experience).',
                    'requiredSkillsListItem9': 'Bonus: if you have knowledge of visualisation tools such as Looker, Tableau, etc.',
                    'requiredSkillsListItem10': 'Bonus: if you have knowledge of Snowplow.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will interface with engineers, product managers and data scientists to understand data needs and implement robust and scalable solutions.',
                    'responsibilitiesListItem2': 'You will work directly with analytics engineers to implement robust and reusable data models.',
                    'responsibilitiesListItem3': 'You will build and maintain data pipelines.',
                    'responsibilitiesListItem4': 'You will ensure data quality and accessibility for all types of data.',
                    'responsibilitiesListItem5': 'You will design and implement ETL processes for new and existing data sources.',
                    'responsibilitiesListItem6': 'You will identify and rectify data quality issues.',
                    'responsibilitiesListItem7': 'You will import data from APIs and several different data sources.',
                    'responsibilitiesListItem8': 'You will write good documentation—for other data engineers, as well as for other colleagues.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },

                'jobOffer8': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Full stack developer.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have experience with JavaScript, Vue.js 2 & 3 and VueX.',
                    'requiredSkillsListItem2': 'You have experience with Nest.js and Python Flask.',
                    'requiredSkillsListItem3': 'You have experience with PostgreSQL, TypeORM.',
                    'requiredSkillsListItem4': 'You have experience with Redis, Kafka, Kubernetes.',
                    'requiredSkillsListItem5': 'You speak English fluently.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer9': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Umbraco Engineer',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as an Umbraco Developer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Umbraco CMS, including architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem4': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem5': 'Excellent analytical skills with a keen eye for detail.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Design, develop, and maintain Umbraco-based websites and applications.',
                    'responsibilitiesListItem2': 'Work closely with project managers, designers, and other developers to create high-quality solutions.',
                    'responsibilitiesListItem3': 'Ensure the performance, quality, and responsiveness of applications.',
                    'responsibilitiesListItem4': 'Troubleshoot and resolve issues in existing applications.',
                    'responsibilitiesListItem5': 'Stay current with emerging trends and technologies related to Umbraco development.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                }
            },
            'work': {
                'project1': {
                    'name': 'Lieferheld',
                    'technologies': 'HTML5, CSS3, JavaScript, jQuery, Backbone.JS, React.JS, NodeJS, Immutable.JS, ExpressJS, PhoneGap, PostgreSQL, Elastic search, Django, Jenkins',
                    'description': 'For three years, Droxic has been assisting this world-leading food delivery company with its front-end and back-end projects. Our long-term partnership has resulted in the development of complex digital platforms for the brand, effectively managing all its global representatives.'
                },
                'project2': {
                    'name': 'ICAEW',
                    'technologies': 'Sitecore CMS',
                    'description': 'Droxic has been responsible for maintaining and enhancing the digital platform for a prestigious global accounting organization that serves over 150,000 members worldwide. Our efforts have ensured smooth operation and the implementation of new features to improve user experience and functionality.'
                },
                'project3': {
                    'name': 'SupperApps',
                    'technologies': 'Linux, Apache, MySQL and PHP (LAMP), Symfony 2, Custom-built CMS system, PhoneGap, Ajax',
                    'description': 'We have developed and maintained three mobile applications (Clients, Chefs, and Drivers), which is a platform for home-cooked and healthy meal options. Everything is managed by one unified CMS, developed with a core focus on the user experience.'
                },
                'project4': {
                    'name': 'IAM',
                    'technologies': 'Microsoft.NET, C #, ASP.NET, Sitefinity CMS, IIS, Grunt, SASS, Bootstrap, JSON, GoogleMaps API',
                    'description': 'Droxic has developed the main website, a web store, and several micro-sites for the Institute of Advanced Motorists. The primary objectives in creating the platform have been to deliver a high level of personalized customer experience and ensure client autonomy.'
                },
                'project5': {
                    'name': 'Ally',
                    'technologies': 'iOS Development, Swift',
                    'description': 'The Ally app assists customers in planning their trips conveniently by comparing price, time, and transportation methods. Droxic’s development efforts have focused on integrating these factors to streamline and enhance the customer experience.'
                },
                'project6': {
                    'name': 'Mamut',
                    'technologies': 'Wordpress, HTML, CSS, JavaScript',
                    'description': 'Mamut is a unique digital adventure that gives its visitors access to the most striking mountains in the world. Our development needed to be as adventurous as our vision, and we succeeded with Project 360, bringing the extreme to people’s screens.'
                },
                'project7': {
                    'name': 'Raiffeisen Bank',
                    'technologies': 'Dojo, jQuery, AJAX, NPM/Bower, Grunt, SASS/SCSS, Bootstrap/ZURB,  Foundation, Handlebars, jQuery, jQuery UI, Classy, Require.js',
                    'description': 'Droxic has collaborated with Raiffeisen Bank to develop a personalized hub that addresses customer demands with a graphical system supporting two communicative levels: emotional and informational, featuring arresting photography and an easy-to-use interface. As a result, Droxic team delivered a fully functional system, providing information on the existing and available modules while also being easily customizable for future module integration.'
                },
                'project8': {
                    'name': 'Mr & Ms Smith',
                    'technologies': 'PHP, Symfony, Zend 2',
                    'description': 'Our development and ongoing maintenance of Mr & Ms Smith has seen it evolve from its humble roots as a booking platform in 2003 into the global travel club it is today, with a million plus members in more than one hundred countries.'
                },
                'project9': {
                    'name': 'Le Mont Botanique',
                    'technologies': 'Drupal 7 E-commerce, HTML, CSS, JavaScript',
                    'description': 'Le Mont Botanique has revolutionized the ordering process, enhancing the customer experience from the first view to checkout. The site\'s structure is entirely manageable for content edits, including accounting, printing reports and invoices, tracking records, and providing multilingual support.'
                },
                'project10': {
                    'name': 'Bubble Nova',
                    'technologies': 'Cocos2d',
                    'description': 'Bubble Nova is a game created for all puzzle lovers. Droxic’s development for this playful project has utilized the unique graphics and characters of Bubble Nova to create an adventure game that takes players through several different zones and levels.'
                },
                'project11': {
                    'name': 'CalConvert',
                    'technologies': 'iOS Development, Swift, PHP, JSON',
                    'description': 'Droxic has developed the ultimate advanced scientific calculator and currency/unit converter, CalConvert, available on iOS and WatchOS. As a result, CalConvert has been recognized as the number one mobile application on the App Store in the utility category.'
                },
                'project12': {
                    'name': 'Timer',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Timer is an app that helps a person optimize and organize their time. Droxic has developed a handy app so that people everywhere can set reliable timers, reminders, countdowns, and alarms, and also create to-do lists and manage tasks.'
                },
                'project13': {
                    'name': 'Spooky Hill Game',
                    'technologies': 'Cocos2d',
                    'description': 'Spooky Hill is a fast-paced arcade game designed and developed by the Droxic mobile team. Our overriding aim throughout this self-initiated project has been to entertain players during the holidays with continuously updated characters and endless fun.'
                },
                'project14': {
                    'name': 'Gothenburg',
                    'technologies': 'Android Development',
                    'description': 'Droxic has created this travel guide app for the city of Gothenburg, including points of interest, restaurants, hotels, GPS positioning, and articles of interest to the user, among several other crucial and compelling features that a tourist would need and appreciate.'
                },
                'project15': {
                    'name': 'Affligem',
                    'technologies': 'Sitecore, HTML, CSS, JavaScript',
                    'description': 'Branding was central to our working process for Affligem. From the research and strategy stages to launching the site, we made sure to reflect Affligem’s legacy and ongoing commitment to creating the finest beers with our development.'
                },
                'project16': {
                    'name': 'Zukunf Läuft',
                    'technologies': 'Phonegap, Cordova, Angular.JS, Ionic',
                    'description': 'This app is based on a scientific questionnaire that helps students manage their expectations and talent with the end goal of finding the career that best suits them. Droxic’s team has developed with a focus on clarity, particularly on how the information is presented to the user.'
                },
                'project17': {
                    'name': 'BraveZone',
                    'technologies': 'Drupal 7, HTML, CSS, JavaScript',
                    'description': 'Droxic has worked closely with the BraveZone team to develop its website portfolio. BraveZone is one of the leading advertising agencies in Sweden, and it was a great experience to team up with its designers and creatives to deliver a brave, new portfolio.'
                },
                'project18': {
                    'name': 'Médecins Sans Frontières',
                    'technologies': 'XML, PHP, MySQL, Apache, JavaScript',
                    'description': 'Over the course of a working relationship with MSF that has spanned years and seen us complete a number of challenging Drupal projects, our experienced consultants have helped break down digital borders for the ‘Doctors without Borders’.'
                },
                'project19': {
                    'name': 'GASAG',
                    'technologies': 'MVC 5, Sitecore 9.1.0, EXM (Integration with SAP), Multisite, Continues Integration - TeamCity, Octopus Deploy, HTML/HTML5, JavaScript, SCSS/CSS, Vue.JS, Gulp, Require.JS, JQuery, Node.JS for StoryBook, RestAPI, Docker',
                    'description': 'Droxic has worked with GASAG Group, which manages 10 websites and portals in total, serving both individual and business clients. Initially, Droxic’s main project aim was to fully revamp the main corporate website in 2020, followed by redesigning, enhancing, and implementing changes for the 10 separate portals in 2021.'
                },
                'project20': {
                    'name': 'MassUp',
                    'technologies': 'PHP, MySQL, Magento 2, HTML5, CSS3, jQuery, React.JS, JavaScript, LESS',
                    'description': 'MassUp, a provider of digital B2B insurance resell solutions, has collaborated with Droxic to upgrade their digital platform. The project aims to develop a robust Magento 2 system that integrates smoothly with various insurer systems. Droxic has successfully migrated MassUp’s old system to continue offering efficient and personalized insurance services and manage data migration seamlessly.'
                },
                'project21': {
                    'name': 'HMD',
                    'technologies': 'GraphQL, Typescript, React, Next.js, Node.js, Emotion, Contentful, Azure, Akamai, Varnish, Nginx, Hotjar, Open Graph, Web-vitals, Module Federation, Google Analytics, Google Optimize',
                    'description': 'HMD Global is an independent Finnish company, the only major European smartphone provider distributing Nokia phones and tablets to the world. In addition to devices and accessories, they offer a suite of services for enterprise customers. Working closely with the in-house HMD team, the Droxic team helped redesign and develop their new corporate website, and associated service portals for improved content, usability, engagement, and lead generation potential, with improved operations.'
                },
                'project22': {
                    'name': 'Siili',
                    'technologies': 'React.JS, JavaScript, TypeScript, Sitefinity, .NET',
                    'description': 'For several years now, Droxic has worked with Silli, a Finnish digital agency that collaborates with well-known brands in the marine, manufacturing, and various other industries. Our long-term partnership has developed complex web and mobile development solutions.'
                },
                'project23': {
                    'name': 'Open Talk',
                    'technologies': 'React Native, DevOps, React.JS, Flutter, Node.JS',
                    'description': 'Droxic has supported OpenTalk in developing and offering a SaaS solution for providers and other platforms, creating a POC and hybrid application, and implementing additional plugins to provide an optimized video platform solution and attract new users.'
                },
                'project24': {
                    'name': 'Fitogram',
                    'technologies': '.NET Core, React.JS',
                    'description': 'Droxic has participated in creating and developing an integrated platform that can be accessed and maintained easily on any device at any time. Our team has designed a complex microservice-based architecture to develop both the frontend (online booking of sports tools, videos on demand, and online courses) and the backend (video releases, attendance monitoring) of the platform. This has encouraged customer loyalty.'
                },
                'project25': {
                    'name': 'Quiet On',
                    'technologies': 'WordPress',
                    'description': 'Droxic has supported QuietOn with some modest tasks, including plugin updates and general maintenance. Recognizing some difficulties with the backend, Droxic overhauled it and switched to WPEngine hosting. After the rebuild, the functionalities were enhanced to boost sales, significantly improve performance, and reduce maintenance costs. This has resulted in greater value and a better user experience.'
                }
            },
            'news': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'News page title.',
                'frontPageDescription': 'Here we need a text for the News section.',

                'newsItem1': {
                    'title': 'Bees become harmful.',
                    'location': 'Berlin',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
                'newsItem2': {
                    'title': 'Immune cell tunnels.',
                    'location': 'Sofia',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
            }
        },
        'menu': {
            'about': 'About',
            'work': 'Portfolio',
            'contact': 'Contact',
            'news': 'Views',
            'careers': 'Careers',
            'blog': 'Blog',
            'droxicOod': '© 2025 Droxic | ',
            'policy': 'Data Protection Policy',
            'logoTooltip': 'Go to home page',
            'openMenuTooltip': 'Open menu',
            'closeMenuTooltip': 'Close menu'
        },
        'privacy': {
            'text': 'We use cookies to enhance your experience. By continuing to visit this site you agree to our use of cookies. <a>More info</a>',
            'buttonText': 'Got it!',
            'page': {
                content: `
                    <h1 class="page-content-common-heading">DATA PROTECTION & COOKIE POLICY</h1>

                    <div class="page-content-common-wrapper">
                        <section>
                            <p>Last uptdate: 25th of May, 2018 /with respect to the General Data Protection Regulation/</p>
                            <p>This Data Protection & Cookie Policy provides general information about us and our privacy policy and cookie use of droxic.com ("website"/"web page").</p>
                        </section>

                        <section>
                            <h2>ABOUT DROXIC LTD.</h2>
                            <p>We are Droxic Ltd. a Bulgarian company, with UIC (Bulgarian Business ID): BG202224741 and registered address at: Sofia, 62B Rodopski Izvor Str. fl.1 apt.4 ("Droxic"). We understand and appreciate the importance of your personal data and its protection as part of your privacy. We will duly protect the integrity of your personal data, whether concerning you independently or in relation to your company, services or products.</p>
                            <p>Please do not hesitate to contact us regarding your personal data at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>

                        <section>
                            <h2>DO WE COLLECT YOUR PERSONAL DATA VIA OUR WEBSITE?</h2>
                            <p>We generally do not seek to collect any personal data while using our website. We do not have a contact form and we do not have any registration forms. The contact details about you constitute personal data and therefore we have only listed our contact details without asking you for specific personal data (like names, e-mail, phone number,etc.). Therefore, the information that you will share with us if you would like to get in touch depends solely on your decision and we will process it for the specific purpose of addressing your inquiry and being able to response.</p>
                            <p>We shall use your personal data only for our correspondence and it will be irrevocably erased after no further responses are expected from any side.</p>
                        </section>

                        <section>
                            <h2>DO WE USE COOKIES?</h2>
                            <p>A cookie is a small text file that a website saves on your computer or mobile device when you visit our website. Droxic may gather information through files such as cookies or log files on the browsing habits. Cookies are files by the website and stored on the user's browser through a web server for the maintenance of the browsing session by storing the IP address of the user (of his or her computer) and other possible browsing data.</p>
                            <p>Droxic uses only web analytics cookies. We use them in order to to measure and analyse user browsing on <a href="http://www.droxic.com">www.droxic.com</a>. Analytics cookies allow us to monitor and analyse the behaviour of users on our website. Web analysis does not allow information like your names or email address to be obtained. The only information obtained relates to the number of users that access the website, the number of pages seen, the frequency and repetition of the visits, their duration, the browser used, the operator providing the service, the language, the device used or the city to which the IP is assigned.</p>
                            <p>Droxic uses the described cookies via Google Analytics (a service, provided by Google Inc.). Google Analytics collects first party cookies, as described above. The information collected using these cookies is sent to Google and is used to evaluate how our web page is being used (for statistics purposes). We will not associate any data gathered with any personally identifying information. We will not link or seek to link any cookie information with any IP address or with the identity of a computer user. Besides the analytics and statistics purpose, Droxic might use advertising cookies to deliver ads that we believe are more relevant to you and your interests. Droxic uses the services of Google and you can read more about their policies here:</p>
                            <ul>
                                <li><p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a></p></li>
                                <li><p><a href="https://policies.google.com/privacy">Privacy Policy – Privacy & Terms – Google</a></p></li>
                            </ul>
                            <p>You can control or delete cookies if you wish - through the options of your browser. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. Be informed that doing so may prevent you from taking full advantage of the website.</p>
                        </section>
                        
                        <section>
                            <h2>DO WE HAVE THE RIGHT TO PROCESS PERSONAL DATA?</h2>
                            <p>Yes, we do and we strictly obey the rules of the General Data Protection Regulation when processing personal data. Most often we process personal data on the following legal grounds:</p>
                            <ul>
                                <li><p>We process it just because we are legally obliged to do so - when processing is necessary for compliance with a legal obligation;</p></li>
                                <li><p>We process it just because we cannot perform our contractual obligations without processing it;</p></li>
                                <li><p>We process it for the purposes of the legitimate interests, pursued by Droxic (for example: marketing).</p></li>
                                <li><p>We process it when the data subject has given us his/her consent to process it.</p></li>
                            </ul>
                            <p>We may also process personal data on a different occasion but only when the processing is permitted by the specific legal grounds, as set out in the General Data Protection Regulation.</p>
                            <p>Sometimes we might have access to personal data which is collected by our clients (the personal data controllers). If Droxic is acting in the capacity of personal data processor, Droxic shall always follow the processing instructions given by the client and also the obligations under the General Data Protection Regulation.</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROCESS PERSONAL DATA OF OUR JOB APPLICANTS ("CAREERS" SECTION)?</h2>
                            <p>By submitting your personal data you are consenting to DROXIC holding and using it in accordance with this Data Protection & Cookie Policy. We collect and process information that you provide when you apply for a job. Such personal details usually contain: your name, your address, your e-mail, your date of birth, qualifications, employment history and basically everything else which you have decided to include.</p>
                            <p>We may also look for other publicly available information about you that you have published on the internet (e.g. via linkedin.com).</p>
                            <p>We rely on legal grounds for processing the personal data of job applicants - both our legitimate interest to conduct our recruitment process and we also process it in order to take steps at the request of data subject prior to entering into a contract (labour agreement).</p>
                            <p>We use your personal data in order to: consider your application for the role for which you have applied and also to consider it in respect of future open roles at Droxic. Therefore we may keep your personal data for up to 6 months after receiving it.</p>
                            <p>Please be informed that at any time you may request and demand that we erase your personal data (usually included in your CV/Cover letter) and we will always comply with your ask, unless you are already employed at Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROCESS PERSONAL DATA OBTAINED VIA <a href="http://www.linkedin.com">LINKEDIN.COM</a></h2>
                            <p>Our HR recruiters use the social platform <a href="http://www.linkedin.com">linkedin.com</a> for the purposes of building a talent pool within the recruitment process regarding a current open position and future positions at Droxic as well. We may processes only publicly available information which you have posted/uploaded: your name, your work experience, education, skills & certifications, accomplishment, contact information (e-mail or link to linkedin profile).</p>
                            <p>In case that we consider you eligible and appropriate to be a member of our team, Droxic might store the personal data which you have listed on your linkedin profile. We may store such information using Droxic's internal systems or via third-party software (namely: <a href="http://www.podio.com">podio.com</a>, a cloud based service owned and managed by Citrix Systems Inc.: <a href="https://www.citrix.com/about/legal/privacy/">https://www.citrix.com/about/legal/privacy/</a> ). Such personal data may be stored by Droxic for a period of up to 3 years after obtaining it.</p>
                            <p>Each "passive candidate" who we consider as appropriate is being contacted by Droxic and duly informed about the processing of his/her personal data. We respect everyone's privacy and if you prefer us to cease processing the personal data obtained via <a href="http://linkedin.com">linkedin.com</a> or want us to stop contacting you, please inform Droxic's recruiter who has contacted you or write us at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> If you request us to erase your personal data we will always comply with your ask, unless you are already employed at Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HAVE YOU RECEIVED A LETTER OR A PHONE CALL FROM US WHICH YOU CONSIDER AS "DIRECT MARKETING"?</h2>
                            <p>We may in some occasions process personal data for the purposes of direct marketing. Such processing is either based on our legitimate interest to do so or it is based on the explicit consent of the data subject. If you do not want us to continue contacting you, please feel free to object to this type of processing by writing to: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> or simply by clicking the "unsubscribe" link). We will always comply with such requests!</p>
                        </section>
                        
                        <section>
                            <h2>HOW DO WE PROTECT PERSONAL DATA WHICH IS PROVIDED BY OUR CLIENTS?</h2>
                            <p>We are committed to ensuring that the personal data which our clients provide us with is safe and secured. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect - both personal data and business secrets.</p>
                            <p>With respect to art. 28 of the General Data Protection Regulation, we request that an explicit Controller-Processor agreement is signed. We are determined to provide sufficient guarantees to implement appropriate technical and organisational measures in such a manner that processing will meet the requirements of this Regulation and ensure the protection of the rights of the data subject. These safeguards will vary depending on the sensitivity, format, location, amount, distribution and storage of the personal data, and include measures designed to keep personal data protected from unauthorized access.</p>
                        </section>
                        
                        <section>
                            <h2>HOW LONG DO WE KEEP THE PERSONAL DATA?</h2>
                            <p>The data retention periods of the personal data depends on the type of information, the purposes for which it is used and other factors.</p>
                            <p>In general, we will retain your personal information for the length of time reasonably needed to fulfill the purposes for which it was processed and no longer than that (unless the law requires or permits a longer retention period). We will also retain and use your information for as long as necessary to resolve disputes and/or enforce our rights and agreements.</p>
                        </section>
                        
                        <section>
                            <h2>WHAT RIGHTS DO YOU HAVE?</h2>
                            <p>The General Data Protection Regulation grants you numerous rights with respect to your personal data: right of access, correction, erasure, restriction of processing, objection and data portability, request a copy of your personal data, or if not satisfied - you may even lodge a complaint before the Data Protection Authority - https://www.cpc.bg/. We will not require you to pay any fee to accessing your personal information or to exercise any of the other rights and yet we may charge a reasonable fee if your request/requests is/are clearly unfounded or excessive.</p>
                            <p>Any questions or requests related to this Policy shall be addressed at: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>
                        
                        <section>
                            <h2>CHANGES TO THIS DATA PROTECTION & COOKIE POLICY</h2>
                            <p>Droxic reserves its right to change and update this Policy at any time. We may also notify data subjects or our clients in case we make some substantial updates.</p>
                        </section>
                    </div>
                `
            }
        }
    },
    'de': {
        'pages': {
            'home': {
                'slides': {
                    'aboutTitle': 'Wir sind Droxic.',
                    'aboutSubtitle': 'Wir erschaffen digitale Erlebnisse.',
                    'aboutButtonText': 'Entdecke',
                    'clientsTitle': 'Kunden.',
                    'clientsSubtitle': 'Globale Marken, großartige Start-ups und befreundete Agenturen.',
                    'clientsButtonText': 'Unsere Kunden',
                    'expertiseTitle': 'Worum es bei uns geht.',
                    'expertiseSubtitle': 'Die digitale Welt ist unser Spielplatz und unsere Leidenschaft.',
                    'expertiseButtonText': 'Unsere Fähigkeiten',
                    'contactsTitle': 'Gut erreichbar.',
                    'contactsSubtitle': 'Wir sind global vertreten mit Büros und Freunden überall.',
                    'contactsButtonText': 'Kontakt'
                },
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digitale Plattformen.',
                        'digitalPlatformsSubtitle': 'Wir programmieren digitale Erlebnisse, die Marken mit Menschen verbinden.',
                        'mobileApplicationsTitle': 'Mobile Applikationen.',
                        'mobileApplicationsSubtitle': 'Wir liefern native und hybride mobile Apps für alle gängigen Betriebssysteme.',
                        'creativeDirectionsTitle': 'Kreative Richtung.',
                        'creativeDirectionsSubtitle': 'Jedes Projekt wird auf einen klaren und kreativen Entwicklungsweg gebracht, der für jeden Kunden einzigartig ist.',
                        'brandingTitle': 'Branding.',
                        'brandingSubtitle': 'Durch unsere Erfahrung und mit Strategie kreieren wir Code, Design und Text für starke Marken.'
                    }
                }
            },
            'about': {
                'description': "Wir sind eine proaktive Digitalagentur mit klarem Hang zur Präzision. Wir sind Partner für unsere Kunden und befreundete Agenturen weltweit und erschaffen Markenerlebnisse im Bereich von Web und Mobile.",
                'expertiseTitle': 'Expertise.',
                'expertiseSubtitle': 'Web & Mobile',
                'expertiseButtonText': 'Was wir machen',
                'teamTitle': 'Team.',
                'teamSubtitle': 'Denker. Macher. Gemeinsam.',
                'teamButtonText': 'Wer wir sind',
                'clientsTitle': 'Kunden.',
                'clientsSubtitle': 'Ohne unsere Kunden sind wir nichts.',
                'clientsButtonText': 'Unsere Kunden',
                'referencesTitle': 'Erfahrung.',
                'referencesSubtitle': 'Überzeugen Sie sich selbst von unserer Qualität.',
                'referencesButtonText': 'Unsere Geschichten',
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digitale Plattformen.',
                        'digitalPlatformsSubtitle': 'Wir programmieren digitale Erlebnisse, die Marken mit Menschen verbinden.',
                        'mobileApplicationsTitle': 'Mobile Applikationen.',
                        'mobileApplicationsSubtitle': 'Wir liefern native und hybride mobile Apps für alle gängigen Betriebssysteme.',
                        'creativeDirectionsTitle': 'Kreative Richtung.',
                        'creativeDirectionsSubtitle': 'Jedes Projekt wird auf einen klaren und kreativen Entwicklungsweg gebracht, der für jeden Kunden einzigartig ist.',
                        'brandingTitle': 'Branding.',
                        'brandingSubtitle': 'Durch unsere Erfahrung und mit Strategie kreieren wir Code, Design und Text für starke Marken.'
                    },
                    'team': {
                        'pavelName': 'Pavel',
                        'pavelPosition': 'CEO',

                        'ilianName': 'Ilian',
                        'ilianPosition': 'CTO',

                        'chopyName': 'Chopy',
                        'chopyPosition': 'CPO',

                        'stoyanName': 'Stoyan',
                        'stoyanPosition': 'CPP',

                        'mariName': 'Mari',
                        'mariPosition': 'Business development',

                        'georgeName': 'George',
                        'georgePosition': 'Business development',

                        'sevdaName': 'Sevda',
                        'sevdaPosition': 'Business development',

                        'arminName': 'Armin',
                        'arminPosition': 'Business development',

                        'joostName': 'Joost',
                        'joostPosition': 'Business development',

                        'richardName': 'Richard',
                        'richardPosition': 'Business development',

                        'viliName': 'Vili',
                        'viliPosition': 'Resource Manager',

                        'zoriName': 'Zori',
                        'zoriPosition': 'Account executive',

                        'loraName': 'Lora',
                        'loraPosition': 'HR Manager',

                        'veronikaName': 'Veronika',
                        'veronikaPosition': 'HR Generalist',

                        'ivaName': 'Iva',
                        'ivaPosition': 'HR Generalist',

                        'natName': 'Nat',
                        'natPosition': 'HR Generalist',

                        'borislavaHrName': 'Borislava',
                        'borislavaHrPosition': 'HR Manager',

                        'hristinaName': 'Hristina',
                        'hristinaPosition': 'Head of Marketing',

                        'julianName': 'Julian',
                        'julianPosition': 'Project manager',

                        'valeriaName': 'Valeria',
                        'valeiraPosition': 'Project manager',

                        'alexName': 'Alex',
                        'alexPosition': 'UX/UI',

                        'konstantinName': 'Konstantin',
                        'konstantinPosition': 'Software engineer',

                        'daniName': 'Dani',
                        'daniPosition': 'Software engineer',

                        'galinName': 'Galin',
                        'galinPosition': 'Software engineer',

                        'kirilName': 'Kiril',
                        'kirilPosition': 'Software engineer',

                        'lyuboName': 'Lyubo',
                        'lyuboPosition': 'Software engineer',

                        'emiName': 'Emi',
                        'emiPosition': 'Software engineer',

                        'zdravkoName': 'Zdravko',
                        'zdravkoPosition': 'Software engineer',

                        'radovanName': 'Radovan',
                        'radovanPosition': 'Branch manager',

                        'tsvetoName': 'Tsveto',
                        'tsvetoPosition': 'Software engineer',

                        'krisiName': 'Krisi',
                        'krisiPosition': 'Software engineer',

                        'borislavaName': 'Borislava',
                        'borislavaPosition': 'Software engineer',

                        'ivanvName': 'Ivan V',
                        'ivanvPosition': 'Software engineer',

                        'kristianName': 'Kris',
                        'kristianPosition': 'Software engineer',

                        'slavName': 'Slav',
                        'slavPosition': 'Software engineer',

                        'dimitarName': 'Dimitar',
                        'dimitarPosition': 'Software engineer',

                        'tsvetanName': 'Tsvetan',
                        'tsvetanPosition': 'Software engineer',

                        'dautName': 'Daut',
                        'dautPosition': 'Software engineer',

                        'dimiName': 'Dimi',
                        'dimiPosition': 'QA Specialist',

                        'bojoName': 'Bojo',
                        'bojoPosition': 'QA Specialist',

                        'foriName': 'Fori',
                        'foriPosition': 'QA Specialist',

                        'chochoName': 'Chocho',
                        'chochoPosition': 'QA Specialist',

                        'vladiName': 'Vladi',
                        'vladiPosition': 'Sys Admin',

                        'saraName': 'Sara',
                        'saraPosition': 'Office Manager',

                        'dobriName': 'Dobri',
                        'dobriPosition': 'Software engineer',

                        'dusanName': 'Dušan',
                        'dusanPosition': 'Software engineer',

                        'darkoName': 'Darko',
                        'darkoPosition': 'Software engineer',

                        'ivanchName': 'Ivan',
                        'ivanchPosition': 'Software engineer',

                        'dimitardName': 'Dimitar',
                        'dimitardPosition': 'Software engineer',

                        'milenaName': 'Milena',
                        'milenaPosition': 'Office Manager Niš',

                        'danielaName': 'Daniela',
                        'danielaPosition': 'Account Executive Assistant',

                        'ninaName': 'Nina',
                        'ninaPosition': 'Software engineer',

                        'vanyoName': 'Vanyo',
                        'vanyoPosition': 'UX/UI',

                        'petarName': 'Petar',
                        'petarPosition': 'Software engineer',

                        'biljanaName': 'Biljana',
                        'biljanaPosition': 'Software engineer',

                        'miroljubName': 'Miroljub',
                        'miroljubPosition': 'Software engineer',

                        'radosName': 'Radoš',
                        'radosPosition': 'Software engineer',

                        'dejanName': 'Dejan',
                        'dejanPosition': 'QA specialist',

                        'lazaName': 'Laza',
                        'lazaPosition': 'Software engineer',

                        'aleksandarName': 'Aleksandar',
                        'aleksandarPosition': 'Software engineer',

                        'markoName': 'Marko',
                        'markoPosition': 'Software engineer',

                        'yanaName': 'Yana',
                        'yanaPosition': 'Project manager',

                        'boyanName': 'Boyan',
                        'boyanPosition': 'Project manager',

                        'ivanDimitrovName': 'Ivan',
                        'ivanDimitrovPosition': 'Software engineer',

                        'stefanName': 'Stefan',
                        'stefanPosition': 'Software engineer',
                    },
                    'references': {
                        'realityBytesName': 'Andreas  Kuno  Kuntze',
                        'realityBytesPosition': 'CEO',
                        'realityBytesCompany': 'Reality  Bytes  Neue  Medien',
                        'realityBytesDescription': 'In zwei Jahren ist das Droxic-Team zu unserer nahtlosen Erweiterung für die Produktion geworden. Unsere Partnerschaft hat sich zu Full-Stack-Entwicklungsdiensten entwickelt. Sie sind immer auf dem neuesten Stand der technologischen Innovation. Ich kann ihnen voll vertrauen.',


                        'lieferheldName': 'Matthias Pfaff',
                        'lieferheldPosition': 'Head of customer experience',
                        'lieferheldCompany': 'Lieferheld',
                        'lieferheldDescription': 'In meinen Teams arbeiteten Entwickler von Droxic an der Entwicklung von Android, iOS, Python und JS. Alle ihre Leute waren sehr nett, kompetent und es machte Spaß, mit ihnen zu arbeiten. Droxic als Unternehmen war immer super zuverlässig und bot viel Flexibilität als Partner, den ich immer und jedem empfehlen würde, der Entwicklungsunterstützung in seinen Projekten oder Produkten benötigt.',


                        'oliverUkName': 'Martin  Nartey',
                        'oliverUkPosition': 'Digital  Project  Lead',
                        'oliverUkCompany': 'Oliver  UK',
                        'oliverUkDescription': "Das digitale Fachwissen von Droxic ist unübertroffen. Bei der Arbeit mit ihnen an einem digitalen Vorzeigeprojekt für einen unserer größten Kunden haben sie die Erwartungen bei allen Anforderungen übertroffen. Sie waren äußerst flexibel und in der Lage, unsere Projektanforderungen zu berücksichtigen. Sie haben dazu beigetragen, die Organisation in ein heikles Projekt zu bringen, und unser Kunde war mit dem Endprodukt absolut zufrieden.",


                        'kweakName': 'Baris  Karatas',
                        'kweakPosition': 'CTO',
                        'kweakCompany': 'Kweak',
                        'kweakDescription': "Ich möchte unsere Wertschätzung für die zuverlässige Zusammenarbeit und die Fähigkeiten hervorheben, die Ihr Team in unser Projekt eingebracht hat. Es war eine großartige Reise und ich werde Droxic auf jeden Fall weiterempfehlen, denn es ist ein großartiges Team. Ich hoffe, dass wir uns in Zukunft wieder mit Euch in Verbindung setzen können.",


                        'exposureName': 'Rory Oliver',
                        'exposurePosition': 'Production Director',
                        'exposureCompany': 'Exposure UK',
                        'exposureDescription': 'Ich habe mit Droxic gearbeitet, als ich Produktionsleiter bei Exposure war, und ich kann ganz ehrlich sagen, dass sie die besten Outsourcing-Entwickler sind, mit denen ich je zusammengearbeitet habe. Wir haben die Zusammenarbeit mit einem mittelgroßen, aber äußerst wichtigen Projekt begonnen, und ihre Leistungen haben unsere Erwartungen übertroffen. In den zwei Folgejahren haben wir an einer Reihe von Projekten gearbeitet und jedes Mal waren Qualität, Service und Lieferung unübertroffen. Ich würde nicht zögern, ihre Leistungen wieder in Anspruch zu nehmen. Sie sind auch tolle Leute und es ist eine Freude, mit ihnen zu arbeiten.',


                        'icaewName': 'Adam  Thompson',
                        'icaewPosition': 'UX  Manager',
                        'icaewCompany': 'ICAEW',
                        'icaewDescription': "Wir sind die Zusammenarbeit mit Droxic als Ergänzung zu unserer in Großbritannien ansässigen Entwicklungsressource eingegangen, und waren von der Qualität ihrer Arbeit und ihrer Flexibilität beeindruckt, die Anzahl der Entwickler, die kurzfristig an unseren Projekten arbeiten, zu erhöhen und zu verringern.",


                        'siiliName': 'Matti Kiviluoto',
                        'siiliPosition': 'CMS Team Lead',
                        'siiliCompany': 'Siili Solutions Plc',
                        'siiliDescription': "Wir waren äußerst zufrieden mit der Professionalität und Kompetenz der Entwickler, mit denen wir zusammengearbeitet haben und unsere Geschäftsbeziehung war stets reibungslos, transparent und fair. Wir waren wiederholt überrascht über die Fähigkeit von Droxic, neue hochqualifizierte Entwickler in so unterschiedlichen Entwicklungsbereichen bereitzustellen. Ich kann Droxic aufrichtig als Softwareentwicklungspartner empfehlen!",


                        'initName': 'Klaus Santel',
                        'initPosition': 'Director Development',
                        'initCompany': 'Init',
                        'initDescription': "Droxic ist seit vielen Jahren ein zuverlässiger Partner, wenn es darum geht, unsere Webprojekte zu unterstützen. Sie sind sehr flexibel und stets bemüht, unsere Erwartungen zu erfüllen. Droxic hat uns in vielen kritischen Projektsituationen und Ressourcenknappheiten schnell und kompetent geholfen. Es ist immer eine Freude, mit ihnen zu arbeiten. Die Kommunikation ist vorbildlich und bei Problemen haben wir immer sehr schnell gemeinsam eine Lösung gefunden."
                    }
                }
            },
            'careers': {
                'detailsButtonLabel': 'Erfahre mehr.',
                'frontPageTitle': 'Wir suchen Talent.',
                'frontPageDescription': 'Werde Teil der Droxic Familie!',
                'wantToJoinTitle': 'Join us.',
                'applyNowButton': 'Apply',
                'gdprText': 'By applying to this position, I give my consent that the personal data included in my CV/resume/motivational letter will be processed in accordance with Droxic\'s Data Protection Policy and for the mentioned period of time (<a>Data Protection Policy</a>)',

                'jobOffer1': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Sitecore Engineer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as a Sitecore Engineer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Sitecore architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with Sitecore development tools and CI/CD pipelines.',
                    'requiredSkillsListItem4': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem5': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem6': 'Excellent problem-solving skills and attention to details.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Develop, implement, and maintain Sitecore-based web applications.',
                    'responsibilitiesListItem2': 'Collaborate with cross-functional teams to define, design, and ship new features.',
                    'responsibilitiesListItem3': 'Optimize applications for maximum speed and scalability',
                    'responsibilitiesListItem4': 'Troubleshoot and debug existing applications, ensuring a high-performance user experience.',
                    'responsibilitiesListItem5': 'Stay updated with the latest industry trends and best practices related to Sitecore.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer2': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Senior Front-end Expert.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'JavaScript is a children’s’ toy for you.',
                    'requiredSkillsListItem2': 'You have extensive experience in Front-End / Full Stack web development.',
                    'requiredSkillsListItem3': 'You have particular affinity for React.js and/or Vue.js, and have participated in software projects using at least one of these technologies.',
                    'requiredSkillsListItem4': 'You have solid knowledge in CSS3 (including SASS/LESS) and HTML5.',
                    'requiredSkillsListItem5': 'You are always open to expanding your knowledge and can easily pick up on a new framework.',
                    'requiredSkillsListItem6': 'You have proven experience using responsive design principles.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will develop sophisticated visual and functional front-end components.',
                    'responsibilitiesListItem3': 'You will build reusable, scalable code; following best practices.',
                    'responsibilitiesListItem4': 'You will work with some of the hottest technologies around.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer3': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Business Development Manager.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'A few years of progressive selling experience to international clients in the digital field.',
                    'requiredSkillsListItem2': 'Working knowledge of the web and mobile development services landscape.',
                    'requiredSkillsListItem3': 'Existing network of end client relationships in Western Europe.',
                    'requiredSkillsListItem4': 'Self-starter, excellent negotiator and a team player.',
                    'requiredSkillsListItem5': 'Excellent presentation, phone and written communication skills.',
                    'requiredSkillsListItem6': 'Highly proficient in English.',
                    'requiredSkillsListItem7': 'Second language proficiency - Swedish/Norwegian/Danish/Finnish or German.',
                    'requiredSkillsListItem8': 'Demonstrated passion for web and mobile innovations.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Aim for growth in market share, volume, turnover and profit.',
                    'responsibilitiesListItem2': 'Approach new prospects and offer Droxic’s services.',
                    'responsibilitiesListItem3': 'Generate new relationships with prospective clients.',
                    'responsibilitiesListItem4': 'Understand customer needs and highlight the features and the benefits of our quality-oriented services.',
                    'responsibilitiesListItem5': 'Work close with internal software development department and PMs to develop strategies and offers that meet the brief of the client.',
                    'responsibilitiesListItem6': 'Identifying further opportunities to serve the existing clients.',
                    'responsibilitiesListItem7': 'Maintain up to date knowledge on trends, issues and opportunities within territory.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer4': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Java senior developer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have practical experience in JSE based web development technologies.',
                    'requiredSkillsListItem2': 'You have practical experience in Spring & Spring Boot.',
                    'requiredSkillsListItem3': 'You have knowledge in CI/CD methodology / tooling (Maven, Jenkins, GitLab-CI/CD, Bamboo).',
                    'requiredSkillsListItem4': 'You have knowledge in container technologies (Docker, Kubernetes, OpenShift/OKD).',
                    'requiredSkillsListItem5': 'You have knowledge in relational Databases (MySQL, Postgres, Oracle).',
                    'requiredSkillsListItem6': 'Knowledge in requirements engineering / technical specifications / scope management.',
                    'requiredSkillsListItem7': 'You have knowledge in architectural approaches.',
                    'requiredSkillsListItem8': 'You have experience in agile methodologies.',
                    'requiredSkillsListItem9': 'It’s a plus if you have knowledge in architectural approaches.',
                    'requiredSkillsListItem10': 'It’s a plus if you have experience in software testing technologies.',
                    'requiredSkillsListItem11': 'It’s a plus if you have knowledge in customizing Content Management Systems (esp. CoreMedia CMS).',
                    'requiredSkillsListItem12': 'It’s a plus if you have knowledge in further Java frameworks (JEE, Quarkus, Wicket, Camunda, Keycloak, form.io, JSF).',
                    'requiredSkillsListItem13': 'You have good written and spoken language skills (English).',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer5': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Application Support Specialist.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have minimum 3-5 years of experience in technical consulting and analytics.',
                    'requiredSkillsListItem2': 'You have good understanding of the issues that an end-user faces with corporate IT Service Management.',
                    'requiredSkillsListItem3': 'You have analytical approach to recurring problems and collected data.',
                    'requiredSkillsListItem4': 'You have good communication skills and ability to maintain good relationship with customers.',
                    'requiredSkillsListItem5': 'You have positive attitude and great interpersonal skills, with a “can do” attitude.',
                    'requiredSkillsListItem6': 'You have excellent written and spoken English language skills.',
                    'requiredSkillsListItem7': 'It’s an advantage if you have relevant software industry experience in any of the following: IT systems, enterprise or infrastructure management, application development and management, security, and/or analytics.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will assist with general inquiries, product support, and troubleshooting via phone, e-mail, and chat.',
                    'responsibilitiesListItem2': 'You will track customer issues using a ticketing system to ensure quick resolution and proper follow-up and documentation.',
                    'responsibilitiesListItem3': 'You will collect data and draw conclusions from it, identifying problems and suggest resolutions.',
                    'responsibilitiesListItem4': 'You will analyze customer needs, goals, strategic plans and constraint in order to advice the best possible use of the platform.',
                    'responsibilitiesListItem5': 'You will have to understand Customer Digital Experience goals, strategic plans and constraints in order to advise on the best use of the product.',
                    'responsibilitiesListItem6': 'You will stay current on product development/releases to a level required for the above activities.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer6': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Project Manager.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'At least 2-3 years of technical delivery experience with a demonstrated PM track record in web and mobile projects.',
                    'requiredSkillsListItem2': 'Full understanding of how to manage an end-to-end technical  project lifecycle, from idea inception through development and launch.',
                    'requiredSkillsListItem3': 'Demonstrated experience simultaneously working on multiple projects with multiple partners.',
                    'requiredSkillsListItem4': 'Experience with both Waterfall and Agile.',
                    'requiredSkillsListItem5': 'Experience facilitating rapid application development teams to deliver technology solutions on time, on budget, and according to client requirements.',
                    'requiredSkillsListItem6': 'Experience using an agile delivery management platform, JIRA preferred.',
                    'requiredSkillsListItem7': 'Fluent English language.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Directing and coordinating multiple web and mobile projects from initiation phase through to implementation phase.',
                    'responsibilitiesListItem2': 'Translation of business requirements into technology solutions and participation in the analysis, evaluation and choice of development toolchains.',
                    'responsibilitiesListItem3': 'Working closely with cross-functional teams in order to define scope, goals and deliverables in alignment with our clients\' goals and our business strategy.',
                    'responsibilitiesListItem4': 'Project estimations and resource planning.',
                    'responsibilitiesListItem5': 'Tracking and reporting on progress. Aiming for delivery of quality solutions on time.',
                    'responsibilitiesListItem6': 'Proactively managing changes and improvements in project scope, identifying issues and driving for resolution.',
                    'responsibilitiesListItem7': 'Managing relationships with new clients and partners.',
                    'responsibilitiesListItem8': 'Organizing and facilitating of daily stand-ups and retrospectives at the end of each sprint. Creating an environment in which the team is continuously learning and encouraged to reflect and improve their delivery capacity.',
                    'responsibilitiesListItem9': 'Coordinating communication between teams and facilitating productive discussions.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer7': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Data Engineer.',
                    'location': 'Sofia',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have 2+ years of industry data engineering experience, including experience in ETL design, implementation and maintenance.',
                    'requiredSkillsListItem2': 'You have proven experience in the data warehouse space, as well as schema design.',
                    'requiredSkillsListItem3': 'You are very good in SQL and at least one modern programming language (Python, Java, etc).',
                    'requiredSkillsListItem4': 'You have profound knowledge of Kubernetes.',
                    'requiredSkillsListItem5': 'You are experienced in designing data solutions within cloud platforms such as AWS (preferred), Google Cloud Platform or Azure.',
                    'requiredSkillsListItem6': 'You have strong knowledge and experience with some distributed data processing technology like Redshift/BigQuery/Snowflake/Spark or similar.',
                    'requiredSkillsListItem7': 'Bonus: If you have experience with Streaming data AWS Kinesis, Kafka or RabbitMQ.',
                    'requiredSkillsListItem8': 'Bonus: if you have Bachelor’s degree in a technical field (computer science, engineering, mathematics,  informatics); advanced degree preferred (or equivalent experience).',
                    'requiredSkillsListItem9': 'Bonus: if you have knowledge of visualisation tools such as Looker, Tableau, etc.',
                    'requiredSkillsListItem10': 'Bonus: if you have knowledge of Snowplow.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will interface with engineers, product managers and data scientists to understand data needs and implement robust and scalable solutions.',
                    'responsibilitiesListItem2': 'You will work directly with analytics engineers to implement robust and reusable data models.',
                    'responsibilitiesListItem3': 'You will build and maintain data pipelines.',
                    'responsibilitiesListItem4': 'You will ensure data quality and accessibility for all types of data.',
                    'responsibilitiesListItem5': 'You will design and implement ETL processes for new and existing data sources.',
                    'responsibilitiesListItem6': 'You will identify and rectify data quality issues.',
                    'responsibilitiesListItem7': 'You will import data from APIs and several different data sources.',
                    'responsibilitiesListItem8': 'You will write good documentation—for other data engineers, as well as for other colleagues.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer8': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Full stack developer.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have experience with JavaScript, Vue.js 2 & 3 and VueX.',
                    'requiredSkillsListItem2': 'You have experience with Nest.js and Python Flask.',
                    'requiredSkillsListItem3': 'You have experience with PostgreSQL, TypeORM.',
                    'requiredSkillsListItem4': 'You have experience with Redis, Kafka, Kubernetes.',
                    'requiredSkillsListItem5': 'You speak English fluently.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer9': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Umbraco Engineer',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as an Umbraco Developer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Umbraco CMS, including architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem4': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem5': 'Excellent analytical skills with a keen eye for detail.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Design, develop, and maintain Umbraco-based websites and applications.',
                    'responsibilitiesListItem2': 'Work closely with project managers, designers, and other developers to create high-quality solutions.',
                    'responsibilitiesListItem3': 'Ensure the performance, quality, and responsiveness of applications.',
                    'responsibilitiesListItem4': 'Troubleshoot and resolve issues in existing applications.',
                    'responsibilitiesListItem5': 'Stay current with emerging trends and technologies related to Umbraco development.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                }
            },
            'work': {
                'project1': {
                    'name': 'Lieferheld',
                    'technologies': 'HTML5, CSS3, JavaScript, jQuery, Backbone.JS, React.JS, NodeJS, Immutable.JS, ExpressJS, PhoneGap, PostgreSQL, Elastic search, Django, Jenkins',
                    'description': 'Seit drei Jahren unterstützen wir dieses weltweit führende Unternehmen im Lieferservice von Lebensmitteln im Front-End- und Back-End-Bereich. Im Rahmen unserer langjährigen Beziehungen wurden komplexe digitale Plattformen für die Marke entwickelt, mit denen alle weltweiten Vertretungen verwaltet werden.'
                },
                'project2': {
                    'name': 'ICAEW',
                    'technologies': 'Sitecore CMS',
                    'description': 'Wir sind stolz darauf, für die renommierte Berufsorganisation für Wirtschaftsprüfer, der mehr als 150.000 Mitglieder weltweit angehören, die Verantwortung für die fortlaufende Pflege und Entwicklung neuer Funktionen zu tragen.'
                },
                'project3': {
                    'name': 'SupperApps',
                    'technologies': 'Linux, Apache, MySQL and PHP (LAMP), Symfony 2, Custom-built CMS system, PhoneGap, Ajax',
                    'description': 'Wir haben drei mobile Apps (Kunden, Köche und Kraftfahrer) neben deren Support entwickelt, die eine Plattform für hausgemachte und gesunde Speisen darstellen. Alles wird über ein einheitliches CMS verwaltet, dessen Schwerpunkt auf der Benutzerfreundlichkeit liegt.'
                },
                'project4': {
                    'name': 'IAM',
                    'technologies': 'Microsoft.NET, C #, ASP.NET, Sitefinity CMS, IIS, Grunt, SASS, Bootstrap, JSON, GoogleMaps API',
                    'description': 'Für das Institute of Advanced Motorists haben wir die Hauptwebsite, einen Webshop und mehrere Mikroseiten entwickelt. Ein hohes Maß an personalisierter Kundenerfahrung und - autonomie waren die Hauptziele bei der Erstellung der Plattform.'
                },
                'project5': {
                    'name': 'Ally',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Mit der Allly-App können Kunden ihre Reisen auf bequemste Weise planen, indem sie Preis, Zeit und Transportmittel vergleichen können. Ziel unserer Entwicklung ist die Gewährleistung des Zusammenspiels all dieser Faktoren neben Rationalisierung des Kundenerlebnisses.'
                },
                'project6': {
                    'name': 'Mamut',
                    'technologies': 'Wordpress, HTML, CSS, JavaScript',
                    'description': 'Mamut ist ein einzigartiges digitales Abenteuer, das seinen Besuchern Zugang zu den beeindruckendsten Bergen der Welt verschafft. Unsere Entwicklung musste so aufregend und erlebnisreich sein wie die Vision, und es gelang uns, das Projekt 360 umzusetzen und das Extreme auf den Bildschirm zu bringen.'
                },
                'project7': {
                    'name': 'Raiffeisen Bank',
                    'technologies': 'Dojo, jQuery, AJAX, NPM/Bower, Grunt, SASS/SCSS, Bootstrap/ZURB,  Foundation, Handlebars, jQuery, jQuery UI, Classy, Require.js',
                    'description': 'Das Konzept dieses Projekts bestand darin, einen personalisierten Hub bereitzustellen, der den Kundenanforderungen über ein grafisches System genügt, das zwei Kommunikationsebenen unterstützt: emotional und informativ, mit beeindruckender Fotografie und benutzerfreundlicher Schnittstelle.'
                },
                'project8': {
                    'name': 'Mr & Ms Smith',
                    'technologies': 'PHP, Symfony, Zend 2',
                    'description': 'Unsere Entwicklung und fortlaufende Wartung von Mr & Ms Smith hat es von den bescheidenen Wurzeln als Buchungsplattform im Jahre 2003 zum globalen Reiseclub gemacht, der heute über eine Million Mitglieder in mehr als einhundert Ländern zählt.'
                },
                'project9': {
                    'name': 'Le Mont Botanique',
                    'technologies': 'Drupal 7 E-commerce, HTML, CSS, JavaScript',
                    'description': 'Unser eShop hat die Bestellabläufe revolutioniert und das Kundenerlebnis von der ersten Ansicht bis zur Kasse verbessert. Die Struktur der Webseite ist vollständig verwaltbar, mit Buchhaltung, Drucken von Berichten und Rechnungen, Track-Records und mehrsprachiger Unterstützung.'
                },
                'project10': {
                    'name': 'Bubble Nova',
                    'technologies': 'Cocos2d',
                    'description': 'Unsere Entwicklung für dieses spielerische Projekt stützte sich auf die einzigartigen Grafiken und Charaktere von Bubble Nova und schuf ein Abenteuerspiel, das die Spieler durch eine Reihe diverser Zonen und Stufen führt.'
                },
                'project11': {
                    'name': 'CalConvert',
                    'technologies': 'iOS Development, Swift, PHP, JSON',
                    'description': 'Diese App ist ein kostenloser Taschenrechner sowie ein Währungs- und Einheiten- Umrechner für iOS und WatchOS. Das klassische Design konzentriert sich auf Funktionalität und Benutzerfreundlichkeit für jedes Alter mit anpassbarer Option für farbenfrohe Skins.'
                },
                'project12': {
                    'name': 'Timer',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Timer ist eine App, die Benutzern bei der Zeitoptimierung und -organisation hilft. Wir haben diese praktische App entwickelt, damit man überall zuverlässige Timer, Erinnerungen, Countdowns und Alarme einstellen, Aufgabenlisten erstellen und Aufgaben verwalten kann.'
                },
                'project13': {
                    'name': 'Spooky Hill Spiel',
                    'technologies': 'Cocos2d',
                    'description': 'Spooky Hill ist ein schnelles Arcade-Spiel, das vom Droxic Mobile Team entwickelt wurde. Unser übergeordnetes Ziel während dieses selbst initiierten Projekts war es, die Spieler während der Feiertage mit ständig aktualisierten Charakteren und endlosem Spaß zu unterhalten.'
                },
                'project14': {
                    'name': 'Gothenburg',
                    'technologies': 'Android Development',
                    'description': 'Wir haben diese Reiseführer-App für die Stadt Göteborg erstellt, einschließlich Sehenswürdigkeiten, Restaurants, Hotels, GPS-Ortung und Artikeln, die für den Benutzer von Interesse sind sowie eine Reihe weiterer wichtiger und überzeugender Funktionen, die ein Reisender benötigen und schätzen würde.'
                },
                'project15': {
                    'name': 'Affligem',
                    'technologies': 'Sitecore, HTML, CSS, JavaScript',
                    'description': 'Für Affligem stand das Branding im Mittelpunkt unseres Arbeitsprozesses. Von der Recherche über die Strategie bis hin zur Auslieferung der Website haben wir sichergestellt, dass das Erbe von Affligem und das kontinuierliche Engagement, die besten Biere zu kreieren, in unserer Arbeit widergespiegelt wurde.'
                },
                'project16': {
                    'name': 'Zukunf Läuft',
                    'technologies': 'Phonegap, Cordova, Angular.JS, Ionic',
                    'description': 'Diese App basiert auf einem wissenschaftlichen Fragebogen, der Studierenden dabei hilft, ihre Erwartungen und Talente zu verwalten, um das Endziel zu erreichen, die für sie am besten geeignete Karriere zu finden. Wir haben uns auf Klarheit konzentriert, insbesondere darauf, wie die Informationen dem Benutzer präsentiert werden.'
                },
                'project17': {
                    'name': 'BraveZone',
                    'technologies': 'Drupal 7, HTML, CSS, JavaScript',
                    'description': 'Wir haben eng mit dem BraveZone-Team bei der Entwicklung des Portfolios für die Homepage des Unternehmens zusammengearbeitet. Es ist eine der führenden Werbeagenturen in Schweden, und es war für uns eine großartige Erfahrung, mit ihren Designern und Kreativen zusammenzuarbeiten, um ein mutiges, neues Portfolio liefern zu können.'
                },
                'project18': {
                    'name': 'Ärzte ohne Grenzen',
                    'technologies': 'XML, PHP, MySQL, Apache, JavaScript',
                    'description': 'Während einer jahrelangen Zusammenarbeit mit Ärzte ohne Grenzen haben unsere erfahrenen Berater dazu beigetragen, die digitalen Grenzen für Ärzte ohne Grenzen zu überwinden.'
                },
                'project19': {
                    'name': 'GASAG',
                    'technologies': 'MVC 5, Sitecore 9.1.0, EXM (Integration with SAP), Multisite, Continues Integration - TeamCity, Octopus Deploy, HTML/HTML5, JavaScript, SCSS/CSS, Vue.JS, Gulp, Require.JS, JQuery, Node.JS for StoryBook, RestAPI, Docker',
                    'description': 'Droxic has worked with GASAG Group, which manages 10 websites and portals in total, serving both individual and business clients. Initially, Droxic’s main project aim was to fully revamp the main corporate website in 2020, followed by redesigning, enhancing, and implementing changes for the 10 separate portals in 2021.'
                },
                'project20': {
                    'name': 'MassUp',
                    'technologies': 'PHP, MySQL, Magento 2, HTML5, CSS3, jQuery, React.JS, JavaScript, LESS',
                    'description': 'MassUp, a provider of digital B2B insurance resell solutions, has collaborated with Droxic to upgrade their digital platform. The project aims to develop a robust Magento 2 system that integrates smoothly with various insurer systems. Droxic has successfully migrated MassUp’s old system to continue offering efficient and personalized insurance services and manage data migration seamlessly.'
                },
                'project21': {
                    'name': 'HMD',
                    'technologies': 'GraphQL, Typescript, React, Next.js, Node.js, Emotion, Contentful, Azure, Akamai, Varnish, Nginx, Hotjar, Open Graph, Web-vitals, Module Federation, Google Analytics, Google Optimize',
                    'description': 'HMD Global is an independent Finnish company, the only major European smartphone provider distributing Nokia phones and tablets to the world. In addition to devices and accessories, they offer a suite of services for enterprise customers. Working closely with the in-house HMD team, the Droxic team helped redesign and develop their new corporate website, and associated service portals for improved content, usability, engagement, and lead generation potential, with improved operations.'
                },
                'project22': {
                    'name': 'Siili',
                    'technologies': 'React.JS, JavaScript, TypeScript, Sitefinity, .NET',
                    'description': 'For several years now, Droxic has worked with Silli, a Finnish digital agency that collaborates with well-known brands in the marine, manufacturing, and various other industries. Our long-term partnership has developed complex web and mobile development solutions.'
                },
                'project23': {
                    'name': 'Open Talk',
                    'technologies': 'React Native, DevOps, React.JS, Flutter, Node.JS',
                    'description': 'Droxic has supported OpenTalk in developing and offering a SaaS solution for providers and other platforms, creating a POC and hybrid application, and implementing additional plugins to provide an optimized video platform solution and attract new users.'
                },
                'project24': {
                    'name': 'Fitogram',
                    'technologies': '.NET Core, React.JS',
                    'description': 'Droxic has participated in creating and developing an integrated platform that can be accessed and maintained easily on any device at any time. Our team has designed a complex microservice-based architecture to develop both the frontend (online booking of sports tools, videos on demand, and online courses) and the backend (video releases, attendance monitoring) of the platform. This has encouraged customer loyalty.'
                },
                'project25': {
                    'name': 'Quiet On',
                    'technologies': 'WordPress',
                    'description': 'Droxic has supported QuietOn with some modest tasks, including plugin updates and general maintenance. Recognizing some difficulties with the backend, Droxic overhauled it and switched to WPEngine hosting. After the rebuild, the functionalities were enhanced to boost sales, significantly improve performance, and reduce maintenance costs. This has resulted in greater value and a better user experience.'
                }
            },
            'news': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'News page title.',
                'frontPageDescription': 'Here we need a text for the News section.',

                'newsItem1': {
                    'title': 'Bees become harmful.',
                    'location': 'Berlin',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
                'newsItem2': {
                    'title': 'Immune cell tunnels.',
                    'location': 'Sofia',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
            }
        },
        'menu': {
            'about': 'Über uns',
            'work': 'Portfolio',
            'contact': 'Kontakt',
            'news': 'Views',
            'careers': 'Karriere',
            'blog': 'Blog',
            'droxicOod': '© 2025 Droxic | ',
            'policy': 'Datenschutz',
            'logoTooltip': 'Zuhause',
            'openMenuTooltip': 'Menü öffnen',
            'closeMenuTooltip': 'Menü schließen'
        },
        'privacy': {
            'text': 'Wir verwenden Cookies, um Ihre Erfahrung zu verbessern. Wenn Sie diese Webseite weiterhin besuchen, stimmen Sie der Verwendung von Cookies zu. <a>Mehr Info</a>',
            'buttonText': 'Verstanden!',
            'page': {
                content: `
                    <h1 class="page-content-common-heading">DATENSCHUTZ- UND COOKIES-RICHTLINIEN</h1>

                    <div class="page-content-common-wrapper">
                        <section>
                            <p>Letzte Aktualisierung: 25. Mai 2018 / in Verbindung mit der Datenschutz-Grundverordnung/</p>
                            <p>Diese Datenschutz- und Cookies-Richtlinie enthält allgemeine Informationen über uns, unsere Datenschutzerklärung und die Cookie-Nutzung auf droxic.com („Website“/„Webseite“).</p>
                        </section>

                        <section>
                            <h2>ÜBER DROXIC LTD.</h2>
                            <p>Wir sind Droxic Ltd., ein bulgarisches Unternehmen unter der EIC (entspricht der Handelsregisternummer): BG202224741, und haben unsere Geschäftanschrift in ul. Rodopski izvor 62B, et. 1, app. 4 Sofia („Droxic“). Wir verstehen und schätzen die Bedeutung Ihrer personenbezogenen Daten und deren Schutz als Teil Ihrer Privatsphäre. Wir werden die Integrität Ihrer personenbezogenen Daten, unabhängig von Ihrer Person oder in Bezug auf Ihr Unternehmen, Ihre Dienstleistungen oder Produkte, angemessen schützen.</p>
                            <p>Bitte zögern Sie nicht, uns bezüglich Ihrer personenbezogenen Daten zu kontaktieren: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>

                        <section>
                            <h2>ERHEBEN WIR IHRE PERSONENBEZOGENEN DATEN ÜBER UNSERE WEBSEITE?</h2>
                            <p>Wir sind im Allgemeinen nicht bemüht, personenbezogene Daten während der Nutzung unserer Webseite zu erheben. Wir haben kein Kontaktformular und auch keine Anmeldeformulare. Die Kontaktdaten über Sie stellen personenbezogene Daten dar, und daher haben wir nur unsere Kontaktdaten aufgeführt, ohne bestimmte personenbezogene Daten (wie Name, E-Mail, Telefonnummer usw.) zu erfragen. Die Informationen, die Sie uns mitteilen, wenn Sie Kontakt aufnehmen möchten, liegen daher ausschließlich in Ihrem Ermessen. Wir werden sie für den spezifischen Zweck verarbeiten, Ihre Anfrage zu bearbeiten und in der Lage zu sein, darauf zu antworten.</p>
                            <p>Wir verwenden Ihre personenbezogenen Daten nur für unsere Korrespondenz, und sie werden unwiderruflich gelöscht, sobald von keiner Seite weitere Antworten erwartet werden.</p>
                        </section>

                        <section>
                            <h2>VERWENDEN WIR COOKIES?</h2>
                            <p>Ein Cookie ist eine kleine Textdatei, die eine Website auf Ihrem Computer oder Mobilgerät speichert, wenn Sie unsere Webseite besuchen. Droxic kann Informationen über die Surfgewohnheiten durch Dateien wie Cookies oder Protokolldateien sammeln. Cookies sind Dateien, die von der Website im Browser des Benutzers über einen Webserver gespeichert werden, um die Browsersitzung aufrechtzuerhalten, indem die IP-Adresse des Benutzers (seines Computers) und andere mögliche Browserdaten gespeichert werden.</p>
                            <p>Droxic verwendet ausschließlich Webanalyse-Cookies. Wir verwenden sie, um das Surfen der Benutzer auf <a href="http://www.droxic.com">www.droxic.com</a> zu messen und zu analysieren. Mithilfe von Analytics-Cookies können wir das Verhalten der Benutzer auf unserer Webseite überwachen und analysieren. Durch die Webanalyse können keine Informationen wie Ihr Name oder Ihre E-Mail-Adresse abgerufen werden. Die einzigen erhaltenen Informationen beziehen sich auf die Anzahl der Benutzer, die auf die Website zugreifen, die Anzahl der angesehenen Seiten, die Häufigkeit und Wiederholung der Besuche, ihre Dauer, den verwendeten Browser, den Betreiber, der den Dienst bereitstellt, die Sprache, das verwendete Gerät oder die Stadt, der die IP zugewiesen ist.</p>
                            <p>Droxic verwendet die beschriebenen Cookies über Google Analytics (einen von Google Inc. angebotenen Dienst). Google Analytics sammelt Erstanbieter-Cookies wie oben beschrieben. Die mit diesen Cookies erhobenen Informationen werden an Google gesendet und zur Bewertung der Verwendung unserer Webseite verwendet (somit für statistische Zwecke). Wir werden keine erhobenen Daten mit personenbezogenen Daten verknüpfen. Wir werden keine Cookie-Informationen mit einer IP-Adresse oder der Identität eines Computerbenutzers verknüpfen oder versuchen, diese zu verknüpfen. Neben den Analyse- und Statistikzwecken verwendet Droxic möglicherweise Werbe-Cookies, um Anzeigen zu schalten, von denen wir glauben, dass sie für Sie und Ihre Interessen relevanter sind. Droxic nutzt die Dienste von Google, und Sie können hier mehr über deren Richtlinien lesen:</p>
                            <ul>
                                <li><p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a></p></li>
                                <li><p><a href="https://policies.google.com/privacy">Privacy Policy – Privacy & Terms – Google</a></p></li>
                            </ul>
                            <p>Über die Optionen Ihres Browsers können Sie Cookies je nach Belieben steuern oder löschen. Sie können alle Cookies löschen, die sich bereits auf Ihrem Computer befinden, und die meisten Browser so einstellen, dass Cookies nicht abgelegt werden. Bitte, beachten Sie, dass Sie dadurch die Webseite möglicherweise nicht vollständig nutzen können.</p>
                        </section>
                        
                        <section>
                            <h2>HABEN WIR DAS RECHT, PERSONENBEZOGENE DATEN ZU VERARBEITEN?</h2>
                            <p>Ja, wir halten uns bei der Verarbeitung personenbezogener Daten strikt an die Regeln der Datenschutz-Grundverordnung. Am häufigsten verarbeiten wir personenbezogene Daten aus folgenden rechtlichen Gründen:</p>
                            <ul>
                                <li><p>Wir verarbeiten sie nur, weil wir gesetzlich dazu verpflichtet sind, d. h. - wenn die Verarbeitung zur Einhaltung einer gesetzlichen Verpflichtung erforderlich ist;</p></li>
                                <li><p>Wir verarbeiten sie nur, weil wir widrigenfalls unsere vertraglichen Verpflichtungen nicht erfüllen können;</p></li>
                                <li><p>Wir verarbeiten sie zu den von Droxic verfolgten berechtigten Interessen (zum Beispiel: Marketing).</p></li>
                                <li><p>Wir verarbeiten sie, wenn die betroffene Person ihre Einwilligung zur Verarbeitung erteilt hat.</p></li>
                            </ul>
                            <p>Wir können personenbezogene Daten auch zu einem anderen Zeitpunkt verarbeiten, jedoch nur, wenn die Verarbeitung aus den in der Datenschutz-Grundverordnung festgelegten rechtlichen Gründen zulässig ist.</p>
                            <p>Manchmal haben wir möglicherweise Zugriff auf personenbezogene Daten, die von unseren Kunden (den Verantwortlichen für personenbezogene Daten) erfasst werden. Wenn Droxic als Verarbeiter personenbezogener Daten handelt, befolgt Droxic stets die vom Kunden gegebenen Verarbeitungsanweisungen sowie die Verpflichtungen aus der Datenschutz- Grundverordnung.</p>
                        </section>
                        
                        <section>
                            <h2>WIE VERARBEITEN WIR PERSONENBEZOGENE DATEN UNSERER STELLENBEWERBER (ABSCHNITT „KARRIERE“)?</h2>
                            <p>Durch die Übermittlung Ihrer personenbezogenen Daten stimmen Sie zu, dass DROXIC diese gemäß dieser Datenschutz- und Cookies-Richtlinie aufbewahrt und verwendet. Wir erheben und verarbeiten Informationen, die Sie bei Ihrer Bewerbung angeben. Solche personenbezogene Daten enthalten in der Regel: Ihren Namen, Ihre Anschrift, Ihre E-Mail- Adresse, Ihr Geburtsdatum, Ihre Qualifikation, Ihre Beschäftigungsgeschichte und im Grunde alles Andere ganz nach Ihrem Belieben.</p>
                            <p>Möglicherweise suchen wir auch nach anderen öffentlich zugänglichen Informationen über Sie, die Sie im Internet veröffentlicht haben (z. B. über linkedin.com).</p>
                            <p>Wir stützen uns auf rechtliche Gründe für die Verarbeitung der personenbezogenen Daten von Bewerbern: sowohl auf unser berechtigtes Interesse an der Durchführung unseres Auswahlverfahrens, als auch darauf, auf Antrag der betroffenen Person vor dem Vertragsabschluss bestimmte Schritte einzuleiten (Arbeitsvertrag).</p>
                            <p>Wir verwenden Ihre personenbezogenen Daten, um: Ihre Bewerbung für die Position, um die Sie sich beworben haben, und auch im Hinblick auf zukünftige offene Stellen bei Droxic zu berücksichtigen. Daher können wir Ihre personenbezogenen Daten bis zu 6 Monate nach Erhalt aufbewahren.</p>
                            <p>Wir möchten Sie darauf hinweisen, dass Sie jederzeit die Löschung Ihrer personenbezogenen Daten (in der Regel enthalten in Ihrem Lebenslauf/Anschreiben) anfordern und verlangen können. Wir werden Ihre Anfrage stets bearbeiten, es sei denn, Sie sind bereits bei Droxic beschäftigt.</p>
                        </section>
                        
                        <section>
                            <h2>WIE VERARBEITEN WIR PERSONENBEZOGENE DATEN, DIE ÜBER <a href="http://www.linkedin.com">LINKEDIN.COM</a> ERHOBEN WERDEN?</h2>
                            <p>Unsere HR-Recruiter nutzen die soziale Plattform <a href="http://www.linkedin.com">linkedin.com</a>, um im Rahmen der Personalbeschaffung einen Talentpool für eine aktuelle offene Position und zukünftige Positionen bei Droxic aufzubauen. Wir können nur öffentlich zugängliche Informationen verarbeiten, die Sie veröffentlicht/hochgeladen haben: Ihren Namen, Ihre Berufserfahrung, Ausbildung, Fähigkeiten und Zertifizierungen, Leistungen, Kontaktdaten (E-Mail oder Link zum LinkedIn-Profil).</p>
                            <p>Für den Fall, dass wir Sie als Mitglied unseres Teams für berechtigt und angemessen halten, speichert Droxic möglicherweise die personenbezogenen Daten, die Sie in Ihrem Linkedin-Profil aufgeführt haben. Wir können solche Informationen über die internen Systeme von Droxic oder über Software von Drittanbietern (nämlich: <a href="http://www.podio.com">podio.com</a>, ein Cloud-basierter Dienst von Citrix Systems Inc.: <a href="https://www.citrix.com/about/legal/privacy/">https://www.citrix.com/about/legal/privacy/</a> ) speichern. Diese personenbezogenen Daten können von Droxic für einen Zeitraum von bis zu 3 Jahren nach Erhalt gespeichert werden.</p>
                            <p>Jeder „passive Kandidat“, den wir für angemessen halten, wird von Droxic kontaktiert und über die Verarbeitung seiner persönlichen Daten informiert. Wir respektieren die Privatsphäre aller und wenn Sie es vorziehen, dass wir die über <a href="http://linkedin.com">linkedin.com</a> erhaltenen personenbezogenen Daten nicht mehr verarbeiten oder Sie nicht mehr kontaktieren, informieren Sie, bitte, den Personalvermittler von Droxic, der Sie kontaktiert hat, oder schreiben Sie uns an: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a>. Wenn Sie bei uns die Löschung Ihrer personenbezogenen Daten beantragen, werden wir immer auf Ihre Anfrage eingehen, es sei denn, Sie sind bereits bei Droxic beschäftigt.</p>
                        </section>
                        
                        <section>
                            <h2>HABEN SIE EINE NACHRICHT ODER EINEN TELEFONANRUF VON UNS ERHALTEN, DIE/DEN SIE ALS „DIREKTMARKETING“ ANSEHEN?</h2>
                            <p>Unter Umständen verarbeiten wir personenbezogene Daten zum Zwecke des Direktmarketings. Eine solche Verarbeitung beruht entweder auf unserem berechtigten Interesse oder auf der ausdrücklichen Zustimmung der betroffenen Person. Wenn Sie nicht möchten, dass wir Sie weiterhin kontaktieren, können Sie dieser Art der Verarbeitung widersprechen, indem Sie an <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> schreiben oder einfach auf den Link „abmelden“ klicken. Wir werden solchen Anfragen immer nachkommen!</p>
                        </section>
                        
                        <section>
                            <h2>WIE SCHÜTZEN WIR PERSONENBEZOGENE DATEN, DIE VON UNSEREN KUNDEN ZUR VERFÜGUNG GESTELLT WERDEN?</h2>
                            <p>Wir setzen uns dafür ein, dass die personenbezogenen Daten, die unsere Kunden uns zur Verfügung stellen, sicher und geschützt sind. Um einen unbefugten Zugriff oder eine unbefugte Weiterleitung zu verhindern, haben wir geeignete physische, elektronische und verwaltungstechnische Verfahren eingerichtet, um die von uns erhobenen Informationen (sowohl personenbezogene Daten, als auch Geschäftsgeheimnisse) zu schützen und zu sichern.</p>
                            <p>In Anlehnung an Artikel 28 der Datenschutz-Grundverordnung setzen wir die Unterzeichnung einer ausdrücklichen Vereinbarung zwischen dem Verantwortlichen und dem Auftragsverarbeiter voraus. Wir sind entschlossen, ausreichende Garantien zu geben, um geeignete technische und organisatorische Maßnahmen so umzusetzen, dass die Verarbeitung den Anforderungen dieser Verordnung entspricht und der Schutz der Rechte der betroffenen Person gewährleistet wird. Diese Sicherheitsvorkehrungen hängen von der Sensibilität, dem Format, dem Ort, der Menge, der Verteilung und der Speicherung der personenbezogenen Daten ab und umfassen Maßnahmen zum Schutz der personenbezogenen Daten vor unbefugtem Zugriff.</p>
                        </section>
                        
                        <section>
                            <h2>WIE LANGE BEHALTEN WIR DIE PERSONENBEZOGENEN DATEN?</h2>
                            <p>Die Aufbewahrungsfristen für personenbezogene Daten hängen von der Art der Informationen, den Zwecken, für die sie verwendet werden, und anderen Faktoren ab.</p>
                            <p>Im Allgemeinen werden wir Ihre personenbezogenen Daten so lange aufbewahren, wie es zur Erfüllung der Zwecke, für die sie verarbeitet wurden, angemessenerweise erforderlich ist, und nicht länger (es sei denn, das Gesetz regelt eine längere Aufbewahrungsfrist oder lässt dies zu). Wir werden Ihre Informationen auch so lange wie nötig speichern und verwenden, um Streitigkeiten beizulegen und/oder unsere Rechte und Vereinbarungen durchzusetzen.</p>
                        </section>
                        
                        <section>
                            <h2>WELCHE RECHTE HABEN SIE?</h2>
                            <p>Die Datenschutz-Grundverordnung gewährt Ihnen zahlreiche Rechte in Bezug auf Ihre personenbezogenen Daten: Auskunftsrecht, Berichtigung, Löschung, Einschränkung der Verarbeitung, Widerspruch und Datenübertragbarkeit, Anforderung einer Kopie Ihrer personenbezogenen Daten oder bei Nichterfüllung können Sie sogar eine Beschwerde bei der Datenschutzbehörde (https://www.cpc.bg/) einlegen. Wir verrechnen keine Gebühr für den Zugriff auf Ihre persönlichen Daten oder die Ausübung anderer Rechte. Wir können jedoch eine angemessene Gebühr erheben, wenn Ihre Anfrage/Anfragen eindeutig unbegründet oder übermäßig ist/sind.</p>
                            <p>Alle Fragen oder Wünsche im Zusammenhang mit dieser Richtlinie sind zu richten an: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>
                        
                        <section>
                            <h2>ÄNDERUNGEN DIESER DATENSCHUTZ- UND COOKIES-RICHTLINIEN</h2>
                            <p>Droxic behält sich vor, diese Richtlinie jederzeit zu ändern und zu aktualisieren. Wir können auch betroffene Personen oder unsere Kunden benachrichtigen, falls wir wesentliche Anpassungen vornehmen.</p>
                        </section>
                    </div>
                `
            }
        }
    },
    'fr': {
        'pages': {
            'home': {
                'slides': {
                    'aboutTitle': 'Bienvenue !',
                    'aboutSubtitle': 'Nous créons des expériences numériques.',
                    'aboutButtonText': 'Découvrir',
                    'clientsTitle': 'Nos Clients',
                    'clientsSubtitle': 'Marques mondiales, entreprises émergentes dynamiques et jeunes pousses.',
                    'clientsButtonText': 'Découvrir nos clients',
                    'expertiseTitle': 'Nos activités',
                    'expertiseSubtitle': 'Le numérique est notre terrain de jeu et notre passion.',
                    'expertiseButtonText': 'Nos compétences',
                    'contactsTitle': 'Portée illimitée.',
                    'contactsSubtitle': 'Présence réellement mondiale, avec de nombreux bureaux et amis.',
                    'contactsButtonText': 'Notre Contact'
                },
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Plateformes Numériques.',
                        'digitalPlatformsSubtitle': 'Nous codons des sites Web et des expériences numériques qui liens les marques à leurs clients.',
                        'mobileApplicationsTitle': 'Applications Mobiles.',
                        'mobileApplicationsSubtitle': 'Nous fournissons des applications mobiles natives et hybrides pour les principaux systèmes d\'exploitation.',
                        'creativeDirectionsTitle': 'Direction Créative.',
                        'creativeDirectionsSubtitle': 'Chaque projet est guidé grâce à une planification claire et créative, unique pour notre client.',
                        'brandingTitle': 'Stratégie de Marque.',
                        'brandingSubtitle': 'La qualité de notre code, de notre design et de nos outils vous permettent de construire des marques fortes.'
                    }
                }
            },
            'about': {
                'description': "Nous sommes une agence numérique proactive obsédée par la recherche de l’excellence. Nous offrons notre fiabilité et notre rigueur à tout partenaire souhaitant bénéficier d’expériences digitales uniques (Web & mobile).",
                'expertiseTitle': 'Notre Expertise.',
                'expertiseSubtitle': 'Numérique: Web et mobile.',
                'expertiseButtonText': 'Que faisons-nous ?',
                'teamTitle': 'Notre Équipe.',
                'teamSubtitle': 'Penseurs. Créateurs. Partenaires.',
                'teamButtonText': 'Qui sommes-nous ?',
                'clientsTitle': 'Nos Clients.',
                'clientsSubtitle': 'Nous ne sommes rien sans nos clients.',
                'clientsButtonText': 'Découvrez nos clients.',
                'referencesTitle': 'Expérience.',
                'referencesSubtitle': 'Voir notre succès de première main.',
                'referencesButtonText': 'Nos réussites',
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Plateformes Numériques.',
                        'digitalPlatformsSubtitle': 'Nous codons des sites Web et des expériences numériques qui liens les marques à leurs clients.',
                        'mobileApplicationsTitle': 'Applications Mobiles.',
                        'mobileApplicationsSubtitle': 'Nous fournissons des applications mobiles natives et hybrides pour les principaux systèmes d\'exploitation.',
                        'creativeDirectionsTitle': 'Direction Créative.',
                        'creativeDirectionsSubtitle': 'Chaque projet est guidé grâce à une planification claire et créative, unique pour notre client.',
                        'brandingTitle': 'Stratégie de Marque.',
                        'brandingSubtitle': 'La qualité de notre code, de notre design et de nos outils vous permettent de construire des marques fortes.'
                    },
                    'team': {
                        'pavelName': 'Pavel',
                        'pavelPosition': 'CEO',

                        'ilianName': 'Ilian',
                        'ilianPosition': 'CTO',

                        'chopyName': 'Chopy',
                        'chopyPosition': 'CPO',

                        'stoyanName': 'Stoyan',
                        'stoyanPosition': 'CPP',

                        'mariName': 'Mari',
                        'mariPosition': 'Business development',

                        'georgeName': 'George',
                        'georgePosition': 'Business development',

                        'sevdaName': 'Sevda',
                        'sevdaPosition': 'Business development',

                        'arminName': 'Armin',
                        'arminPosition': 'Business development',

                        'joostName': 'Joost',
                        'joostPosition': 'Business development',

                        'richardName': 'Richard',
                        'richardPosition': 'Business development',

                        'viliName': 'Vili',
                        'viliPosition': 'Resource Manager',

                        'zoriName': 'Zori',
                        'zoriPosition': 'Account executive',

                        'loraName': 'Lora',
                        'loraPosition': 'HR Manager',

                        'veronikaName': 'Veronika',
                        'veronikaPosition': 'HR Generalist',

                        'ivaName': 'Iva',
                        'ivaPosition': 'HR Generalist',

                        'natName': 'Nat',
                        'natPosition': 'HR Generalist',

                        'borislavaHrName': 'Borislava',
                        'borislavaHrPosition': 'HR Manager',

                        'hristinaName': 'Hristina',
                        'hristinaPosition': 'Head of Marketing',

                        'julianName': 'Julian',
                        'julianPosition': 'Project manager',

                        'valeriaName': 'Valeria',
                        'valeiraPosition': 'Project manager',

                        'alexName': 'Alex',
                        'alexPosition': 'UX/UI',

                        'konstantinName': 'Konstantin',
                        'konstantinPosition': 'Software engineer',

                        'daniName': 'Dani',
                        'daniPosition': 'Software engineer',

                        'galinName': 'Galin',
                        'galinPosition': 'Software engineer',

                        'kirilName': 'Kiril',
                        'kirilPosition': 'Software engineer',

                        'lyuboName': 'Lyubo',
                        'lyuboPosition': 'Software engineer',

                        'emiName': 'Emi',
                        'emiPosition': 'Software engineer',

                        'zdravkoName': 'Zdravko',
                        'zdravkoPosition': 'Software engineer',

                        'radovanName': 'Radovan',
                        'radovanPosition': 'Branch manager',

                        'tsvetoName': 'Tsveto',
                        'tsvetoPosition': 'Software engineer',

                        'krisiName': 'Krisi',
                        'krisiPosition': 'Software engineer',

                        'borislavaName': 'Borislava',
                        'borislavaPosition': 'Software engineer',

                        'ivanvName': 'Ivan V',
                        'ivanvPosition': 'Software engineer',

                        'kristianName': 'Kris',
                        'kristianPosition': 'Software engineer',

                        'slavName': 'Slav',
                        'slavPosition': 'Software engineer',

                        'dimitarName': 'Dimitar',
                        'dimitarPosition': 'Software engineer',

                        'tsvetanName': 'Tsvetan',
                        'tsvetanPosition': 'Software engineer',

                        'dautName': 'Daut',
                        'dautPosition': 'Software engineer',

                        'dimiName': 'Dimi',
                        'dimiPosition': 'QA Specialist',

                        'bojoName': 'Bojo',
                        'bojoPosition': 'QA Specialist',

                        'foriName': 'Fori',
                        'foriPosition': 'QA Specialist',

                        'chochoName': 'Chocho',
                        'chochoPosition': 'QA Specialist',

                        'vladiName': 'Vladi',
                        'vladiPosition': 'Sys Admin',

                        'saraName': 'Sara',
                        'saraPosition': 'Office Manager',

                        'dobriName': 'Dobri',
                        'dobriPosition': 'Software engineer',

                        'dusanName': 'Dušan',
                        'dusanPosition': 'Software engineer',

                        'darkoName': 'Darko',
                        'darkoPosition': 'Software engineer',

                        'ivanchName': 'Ivan',
                        'ivanchPosition': 'Software engineer',

                        'dimitardName': 'Dimitar',
                        'dimitardPosition': 'Software engineer',

                        'milenaName': 'Milena',
                        'milenaPosition': 'Office Manager Niš',

                        'danielaName': 'Daniela',
                        'danielaPosition': 'Account Executive Assistant',

                        'ninaName': 'Nina',
                        'ninaPosition': 'Software engineer',

                        'vanyoName': 'Vanyo',
                        'vanyoPosition': 'UX/UI',

                        'petarName': 'Petar',
                        'petarPosition': 'Software engineer',

                        'biljanaName': 'Biljana',
                        'biljanaPosition': 'Software engineer',

                        'miroljubName': 'Miroljub',
                        'miroljubPosition': 'Software engineer',

                        'radosName': 'Radoš',
                        'radosPosition': 'Software engineer',

                        'dejanName': 'Dejan',
                        'dejanPosition': 'QA specialist',

                        'lazaName': 'Laza',
                        'lazaPosition': 'Software engineer',

                        'aleksandarName': 'Aleksandar',
                        'aleksandarPosition': 'Software engineer',

                        'markoName': 'Marko',
                        'markoPosition': 'Software engineer',

                        'yanaName': 'Yana',
                        'yanaPosition': 'Project manager',

                        'boyanName': 'Boyan',
                        'boyanPosition': 'Project manager',

                        'ivanDimitrovName': 'Ivan',
                        'ivanDimitrovPosition': 'Software engineer',

                        'stefanName': 'Stefan',
                        'stefanPosition': 'Software engineer',
                    },
                    'references': {
                        'realityBytesName': 'Andreas  Kuno  Kuntze',
                        'realityBytesPosition': 'CEO',
                        'realityBytesCompany': 'Reality  Bytes  Neue  Medien',
                        'realityBytesDescription': 'Depuis deux ans, l’équipe Droxic est devenue notre prolongement intégré pour tout ce qui relève de la production. Notre partenariat s\'est développé pour offrir des services de développement full-stack. Ils sont toujours à la pointe de l\'innovation technologique. Je peux leur faire entièrement confiance.',


                        'lieferheldName': 'Matthias Pfaff',
                        'lieferheldPosition': 'Head of customer experience',
                        'lieferheldCompany': 'Lieferheld',
                        'lieferheldDescription': 'J’avais des développeurs de Droxic qui travaillaient dans mes équipes pour le développement sur Android, iOS, Python et JS. Tous leurs employés étaient très agréables, compétents et ce fut un réel plaisir de travailler avec eux. En tant qu’entreprise, Droxic a toujours été très fiable et a permis de collaborer avec flexibilité. Un partenaire que je recommanderais et recommande toujours et à toutes elles et ceux qui ont besoin de développement de qualité pour leurs projets ou produits.',


                        'oliverUkName': 'Martin  Nartey',
                        'oliverUkPosition': 'Digital  Project  Lead',
                        'oliverUkCompany': 'Oliver  UK',
                        'oliverUkDescription': "L’expertise numérique de Droxic est sans égale. Travaillant avec eux sur un showcase digital pour l'un de nos plus gros clients, ils ont su dépasser les attentes concernant toutes les exigences que nous avions transmises. Leur flexibilité et sens de la planification ont permis de répondre à tous les besoins du projet. Ils nous ont aidé à mener à bien l'organisation d’un projet qui était fort complexe, et notre client fut absolument ravi du produit final.",


                        'kweakName': 'Baris  Karatas',
                        'kweakPosition': 'CTO',
                        'kweakCompany': 'Kweak',
                        'kweakDescription': "Je tiens à souligner notre gratitude pour la coopération fiable et les compétences que votre équipe a apportées à notre projet. C’était une belle réalisation professionnelle, et compte bien recommander Droxic et son excellente équipe de spécialistes. J'espère pouvoir vous rencontrer à nouveau à l'avenir.",


                        'exposureName': 'Rory Oliver',
                        'exposurePosition': 'Production Director',
                        'exposureCompany': 'Exposure UK',
                        'exposureDescription': 'J\'ai travaillé avec Droxic alors que j\'étais directeur de production chez Exposure, et je peux dire en toute honnêteté qu\'ils sont le meilleur prestataire externe avec lequel j\'ai jamais travaillé. Nous avons commencé notre fructueuse coopération avec un projet de taille moyenne, mais extrêmement important, et leur travail a dépassé les attentes du client. Au cours des deux années suivantes, nous avons travaillé sur un grand nombre de projets et, chaque fois, la qualité et le service furent impeccables. Je n\'hésiterais pas à utiliser à nouveau leurs compétences à l’avenir. En outre, ce sont des personnes formidables et c’est un vrai plaisir de travailler avec eux.',


                        'icaewName': 'Adam  Thompson',
                        'icaewPosition': 'UX  Manager',
                        'icaewCompany': 'ICAEW',
                        'icaewDescription': "Nous avons commencé à utiliser Droxic pour compléter nos besoins en développement, et nous avons été impressionnés par la qualité de leur travail et par leur souplesse business permettant facilement d’agrandir ou de réduire le nombre de développeurs travaillant sur nos projets à court terme.",


                        'siiliName': 'Matti Kiviluoto',
                        'siiliPosition': 'CMS Team Lead',
                        'siiliCompany': 'Siili Solutions Plc',
                        'siiliDescription': "Nous avons été extrêmement satisfaits du professionnalisme et de la compétence des développeurs avec lesquels nous avons travaillé. Nos relations d’affaires ont toujours été harmonieuses, transparentes et équitables. Nous avons souvent été surpris par la capacité de Droxic à fournir de nouveaux développeurs hautement qualifiés dans des domaines de développement pourtant très variés. Je peux sincèrement recommander Droxic en tant que partenaire de développement logiciel!",


                        'initName': 'Klaus Santel',
                        'initPosition': 'Director Development',
                        'initCompany': 'Init',
                        'initDescription': "Depuis de nombreuses années, Droxic est un partenaire fiable quand il s’agit de soutenir nos projets Web. Ils sont très flexibles et toujours engagés à nous permettre d’atteindre nos objectifs. Droxic nous a aidé dans de nombreuses situations critiques en matière de projets et de pénuries de ressources, et ce toujours de manière rapide et compétente. C’est toujours un plaisir de travailler avec eux. La communication est exemplaire, et, en cas de problèmes, nous avons toujours trouvé une solution efficiente ensemble et très rapidement."
                    }
                }
            },
            'careers': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'Nous recrutons des talents.',
                'frontPageDescription': 'Faites défiler pour découvrir nos opportunités.',
                'wantToJoinTitle': 'Join us.',
                'applyNowButton': 'Apply',
                'gdprText': 'By applying to this position, I give my consent that the personal data included in my CV/resume/motivational letter will be processed in accordance with Droxic\'s Data Protection Policy and for the mentioned period of time (<a>Data Protection Policy</a>)',

                'jobOffer1': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Sitecore Engineer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as a Sitecore Engineer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Sitecore architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with Sitecore development tools and CI/CD pipelines.',
                    'requiredSkillsListItem4': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem5': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem6': 'Excellent problem-solving skills and attention to details.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Develop, implement, and maintain Sitecore-based web applications.',
                    'responsibilitiesListItem2': 'Collaborate with cross-functional teams to define, design, and ship new features.',
                    'responsibilitiesListItem3': 'Optimize applications for maximum speed and scalability',
                    'responsibilitiesListItem4': 'Troubleshoot and debug existing applications, ensuring a high-performance user experience.',
                    'responsibilitiesListItem5': 'Stay updated with the latest industry trends and best practices related to Sitecore.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer2': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Senior Front-end Expert.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'JavaScript is a children’s’ toy for you.',
                    'requiredSkillsListItem2': 'You have extensive experience in Front-End / Full Stack web development.',
                    'requiredSkillsListItem3': 'You have particular affinity for React.js and/or Vue.js, and have participated in software projects using at least one of these technologies.',
                    'requiredSkillsListItem4': 'You have solid knowledge in CSS3 (including SASS/LESS) and HTML5.',
                    'requiredSkillsListItem5': 'You are always open to expanding your knowledge and can easily pick up on a new framework.',
                    'requiredSkillsListItem6': 'You have proven experience using responsive design principles.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will develop sophisticated visual and functional front-end components.',
                    'responsibilitiesListItem3': 'You will build reusable, scalable code; following best practices.',
                    'responsibilitiesListItem4': 'You will work with some of the hottest technologies around.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer3': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Business Development Manager.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'A few years of progressive selling experience to international clients in the digital field.',
                    'requiredSkillsListItem2': 'Working knowledge of the web and mobile development services landscape.',
                    'requiredSkillsListItem3': 'Existing network of end client relationships in Western Europe.',
                    'requiredSkillsListItem4': 'Self-starter, excellent negotiator and a team player.',
                    'requiredSkillsListItem5': 'Excellent presentation, phone and written communication skills.',
                    'requiredSkillsListItem6': 'Highly proficient in English.',
                    'requiredSkillsListItem7': 'Second language proficiency - Swedish/Norwegian/Danish/Finnish or German.',
                    'requiredSkillsListItem8': 'Demonstrated passion for web and mobile innovations.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Aim for growth in market share, volume, turnover and profit.',
                    'responsibilitiesListItem2': 'Approach new prospects and offer Droxic’s services.',
                    'responsibilitiesListItem3': 'Generate new relationships with prospective clients.',
                    'responsibilitiesListItem4': 'Understand customer needs and highlight the features and the benefits of our quality-oriented services.',
                    'responsibilitiesListItem5': 'Work close with internal software development department and PMs to develop strategies and offers that meet the brief of the client.',
                    'responsibilitiesListItem6': 'Identifying further opportunities to serve the existing clients.',
                    'responsibilitiesListItem7': 'Maintain up to date knowledge on trends, issues and opportunities within territory.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer4': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Java senior developer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have practical experience in JSE based web development technologies.',
                    'requiredSkillsListItem2': 'You have practical experience in Spring & Spring Boot.',
                    'requiredSkillsListItem3': 'You have knowledge in CI/CD methodology / tooling (Maven, Jenkins, GitLab-CI/CD, Bamboo).',
                    'requiredSkillsListItem4': 'You have knowledge in container technologies (Docker, Kubernetes, OpenShift/OKD).',
                    'requiredSkillsListItem5': 'You have knowledge in relational Databases (MySQL, Postgres, Oracle).',
                    'requiredSkillsListItem6': 'Knowledge in requirements engineering / technical specifications / scope management.',
                    'requiredSkillsListItem7': 'You have knowledge in architectural approaches.',
                    'requiredSkillsListItem8': 'You have experience in agile methodologies.',
                    'requiredSkillsListItem9': 'It’s a plus if you have knowledge in architectural approaches.',
                    'requiredSkillsListItem10': 'It’s a plus if you have experience in software testing technologies.',
                    'requiredSkillsListItem11': 'It’s a plus if you have knowledge in customizing Content Management Systems (esp. CoreMedia CMS).',
                    'requiredSkillsListItem12': 'It’s a plus if you have knowledge in further Java frameworks (JEE, Quarkus, Wicket, Camunda, Keycloak, form.io, JSF).',
                    'requiredSkillsListItem13': 'You have good written and spoken language skills (English).',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer5': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Application Support Specialist.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have minimum 3-5 years of experience in technical consulting and analytics.',
                    'requiredSkillsListItem2': 'You have good understanding of the issues that an end-user faces with corporate IT Service Management.',
                    'requiredSkillsListItem3': 'You have analytical approach to recurring problems and collected data.',
                    'requiredSkillsListItem4': 'You have good communication skills and ability to maintain good relationship with customers.',
                    'requiredSkillsListItem5': 'You have positive attitude and great interpersonal skills, with a “can do” attitude.',
                    'requiredSkillsListItem6': 'You have excellent written and spoken English language skills.',
                    'requiredSkillsListItem7': 'It’s an advantage if you have relevant software industry experience in any of the following: IT systems, enterprise or infrastructure management, application development and management, security, and/or analytics.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will assist with general inquiries, product support, and troubleshooting via phone, e-mail, and chat.',
                    'responsibilitiesListItem2': 'You will track customer issues using a ticketing system to ensure quick resolution and proper follow-up and documentation.',
                    'responsibilitiesListItem3': 'You will collect data and draw conclusions from it, identifying problems and suggest resolutions.',
                    'responsibilitiesListItem4': 'You will analyze customer needs, goals, strategic plans and constraint in order to advice the best possible use of the platform.',
                    'responsibilitiesListItem5': 'You will have to understand Customer Digital Experience goals, strategic plans and constraints in order to advise on the best use of the product.',
                    'responsibilitiesListItem6': 'You will stay current on product development/releases to a level required for the above activities.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer6': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Project Manager.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'At least 2-3 years of technical delivery experience with a demonstrated PM track record in web and mobile projects.',
                    'requiredSkillsListItem2': 'Full understanding of how to manage an end-to-end technical  project lifecycle, from idea inception through development and launch.',
                    'requiredSkillsListItem3': 'Demonstrated experience simultaneously working on multiple projects with multiple partners.',
                    'requiredSkillsListItem4': 'Experience with both Waterfall and Agile.',
                    'requiredSkillsListItem5': 'Experience facilitating rapid application development teams to deliver technology solutions on time, on budget, and according to client requirements.',
                    'requiredSkillsListItem6': 'Experience using an agile delivery management platform, JIRA preferred.',
                    'requiredSkillsListItem7': 'Fluent English language.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Directing and coordinating multiple web and mobile projects from initiation phase through to implementation phase.',
                    'responsibilitiesListItem2': 'Translation of business requirements into technology solutions and participation in the analysis, evaluation and choice of development toolchains.',
                    'responsibilitiesListItem3': 'Working closely with cross-functional teams in order to define scope, goals and deliverables in alignment with our clients\' goals and our business strategy.',
                    'responsibilitiesListItem4': 'Project estimations and resource planning.',
                    'responsibilitiesListItem5': 'Tracking and reporting on progress. Aiming for delivery of quality solutions on time.',
                    'responsibilitiesListItem6': 'Proactively managing changes and improvements in project scope, identifying issues and driving for resolution.',
                    'responsibilitiesListItem7': 'Managing relationships with new clients and partners.',
                    'responsibilitiesListItem8': 'Organizing and facilitating of daily stand-ups and retrospectives at the end of each sprint. Creating an environment in which the team is continuously learning and encouraged to reflect and improve their delivery capacity.',
                    'responsibilitiesListItem9': 'Coordinating communication between teams and facilitating productive discussions.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer7': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Data Engineer.',
                    'location': 'Sofia',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have 2+ years of industry data engineering experience, including experience in ETL design, implementation and maintenance.',
                    'requiredSkillsListItem2': 'You have proven experience in the data warehouse space, as well as schema design.',
                    'requiredSkillsListItem3': 'You are very good in SQL and at least one modern programming language (Python, Java, etc).',
                    'requiredSkillsListItem4': 'You have profound knowledge of Kubernetes.',
                    'requiredSkillsListItem5': 'You are experienced in designing data solutions within cloud platforms such as AWS (preferred), Google Cloud Platform or Azure.',
                    'requiredSkillsListItem6': 'You have strong knowledge and experience with some distributed data processing technology like Redshift/BigQuery/Snowflake/Spark or similar.',
                    'requiredSkillsListItem7': 'Bonus: If you have experience with Streaming data AWS Kinesis, Kafka or RabbitMQ.',
                    'requiredSkillsListItem8': 'Bonus: if you have Bachelor’s degree in a technical field (computer science, engineering, mathematics,  informatics); advanced degree preferred (or equivalent experience).',
                    'requiredSkillsListItem9': 'Bonus: if you have knowledge of visualisation tools such as Looker, Tableau, etc.',
                    'requiredSkillsListItem10': 'Bonus: if you have knowledge of Snowplow.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will interface with engineers, product managers and data scientists to understand data needs and implement robust and scalable solutions.',
                    'responsibilitiesListItem2': 'You will work directly with analytics engineers to implement robust and reusable data models.',
                    'responsibilitiesListItem3': 'You will build and maintain data pipelines.',
                    'responsibilitiesListItem4': 'You will ensure data quality and accessibility for all types of data.',
                    'responsibilitiesListItem5': 'You will design and implement ETL processes for new and existing data sources.',
                    'responsibilitiesListItem6': 'You will identify and rectify data quality issues.',
                    'responsibilitiesListItem7': 'You will import data from APIs and several different data sources.',
                    'responsibilitiesListItem8': 'You will write good documentation—for other data engineers, as well as for other colleagues.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer8': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Full stack developer.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have experience with JavaScript, Vue.js 2 & 3 and VueX.',
                    'requiredSkillsListItem2': 'You have experience with Nest.js and Python Flask.',
                    'requiredSkillsListItem3': 'You have experience with PostgreSQL, TypeORM.',
                    'requiredSkillsListItem4': 'You have experience with Redis, Kafka, Kubernetes.',
                    'requiredSkillsListItem5': 'You speak English fluently.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer9': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Umbraco Engineer',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as an Umbraco Developer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Umbraco CMS, including architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem4': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem5': 'Excellent analytical skills with a keen eye for detail.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Design, develop, and maintain Umbraco-based websites and applications.',
                    'responsibilitiesListItem2': 'Work closely with project managers, designers, and other developers to create high-quality solutions.',
                    'responsibilitiesListItem3': 'Ensure the performance, quality, and responsiveness of applications.',
                    'responsibilitiesListItem4': 'Troubleshoot and resolve issues in existing applications.',
                    'responsibilitiesListItem5': 'Stay current with emerging trends and technologies related to Umbraco development.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                }
            },
            'work': {
                'project1': {
                    'name': 'Lieferheld',
                    'technologies': 'HTML5, CSS3, JavaScript, jQuery, Backbone.JS, React.JS, NodeJS, Immutable.JS, ExpressJS, PhoneGap, PostgreSQL, Elastic search, Django, Jenkins',
                    'description': 'Durant trois années, nous avons aidé cette entreprise de livraison de nourriture de rang mondial dans ses besoins de front-end et back-end. Notre partenariat long terme nous a permis de développer pour la marque des plates-formes numériques complexes utilisées par ses représentants dans le monde entier.'
                },
                'project2': {
                    'name': 'ICAEW',
                    'technologies': 'Sitecore CMS',
                    'description': 'Nous sommes fiers d\'avoir été chargés de la maintenance et du développement de nouvelles fonctionnalités pour cette très célèbre organisation professionnelle de comptables, qui comprend plus de 150 000 membres à travers le monde.'
                },
                'project3': {
                    'name': 'SupperApps',
                    'technologies': 'Linux, Apache, MySQL and PHP (LAMP), Symfony 2, Custom-built CMS system, PhoneGap, Ajax',
                    'description': 'Nous avons développé et maintenu trois applications mobiles (Clients, Chefs et Livreurs), pour cette plate-forme de création de repas sains. Le tout est géré par un seul CMS unifié, développé avec une approche résolument orientée expérience utilisateur.'
                },
                'project4': {
                    'name': 'IAM',
                    'technologies': 'Microsoft.NET, C #, ASP.NET, Sitefinity CMS, IIS, Grunt, SASS, Bootstrap, JSON, GoogleMaps API',
                    'description': 'Nous avons développé le site Web principal, une boutique en ligne et plusieurs micro-sites pour l’Institute of Advanced Motorists. L\'excellence de l’expérience client était l’objectif-clé de la création de cette plateforme.'
                },
                'project5': {
                    'name': 'Ally',
                    'technologies': 'iOS Development, Swift',
                    'description': 'L\'application Allly aide ses clients à planifier leurs voyages de la manière la plus pratique, en comparant les prix, le temps et les moyens de transport. Notre développement visait à associer tous ces facteurs et à optimiser l\'expérience client.'
                },
                'project6': {
                    'name': 'Mamut',
                    'technologies': 'Wordpress, HTML, CSS, JavaScript',
                    'description': 'Mamut est une aventure numérique unique qui offre à ses visiteurs un accès aux montagnes les plus impressionnantes du monde. Notre développement devait être aussi aventureux que cette vision singulière, et nous avons créé ensemble le projet 360, permettant d’offrir l\'extrême à tout un chacun.'
                },
                'project7': {
                    'name': 'Raiffeisen Bank',
                    'technologies': 'Dojo, jQuery, AJAX, NPM/Bower, Grunt, SASS/SCSS, Bootstrap/ZURB,  Foundation, Handlebars, jQuery, jQuery UI, Classy, Require.js',
                    'description': 'Le concept de ce projet consistait à fournir un hub personnalisé répondant aux besoins des clients grâce à un système graphique supportant deux niveaux de communication : émotionnel et informatif.'
                },
                'project8': {
                    'name': 'Mr & Ms Smith',
                    'technologies': 'PHP, Symfony, Zend 2',
                    'description': 'Notre développement et maintenance continue de Mr. & Ms. Smith l\'ont vu évoluer de ses humbles débuts en tant que plate-forme de réservation au club de voyages mondial qu\'il est devenu aujourd\'hui, comprenant plus d’un million de membres dans plus de cent pays.'
                },
                'project9': {
                    'name': 'Le Mont Botanique',
                    'technologies': 'Drupal 7 E-commerce, HTML, CSS, JavaScript',
                    'description': 'Notre boutique électronique a révolutionné le processus de commande en améliorant l\'expérience client de la première vue au tunnel de commande. La structure du site est entièrement gérable, avec intégration de la comptabilité, la création automatisée de rapports, la gestion des factures, etc., le tout avec un support multilangues.'
                },
                'project10': {
                    'name': 'Bubble Nova',
                    'technologies': 'Cocos2d',
                    'description': 'Grâce à ce projet ludique créé de A à Z, nous avons pu repousser les limites graphiques et de programmation pour arriver à la création d’un jeu d’aventure au gameplay original dans un univers visuel de toute beauté.'
                },
                'project11': {
                    'name': 'CalConvert',
                    'technologies': 'iOS Development, Swift, PHP, JSON',
                    'description': 'Cette application est une calculatrice gratuite, ainsi qu\'un convertisseur de devises et d\'unités de mesure pour iOS et WatchOS. Son design classique est axé sur la fonctionnalité et l\'expérience utilisateur, pour des utilisateurs de tous âges.'
                },
                'project12': {
                    'name': 'Timer',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Timer est une application qui aide ses utilisateurs à optimiser et organiser leur temps. Nous avons développé cette application pratique pour permettre aux utilisateurs du monde entier de définir des minuteries, des rappels, des comptes à rebours et des alarmes fiables, permettant aussi de créer et gérer des listes de tâches.'
                },
                'project13': {
                    'name': 'Spooky Hill Game',
                    'technologies': 'Cocos2d',
                    'description': 'C’est un jeu d’arcade rapide qui fait appel aux réflexes les plus précis et au sang-froid le plus impérieux. Il a été entièrement créé et développé par notre équipe de dev mobile. Nous avions plusieurs objectifs avec ce projet « maison » : montrer d’une manière ludique et efficace nos compétences tout en offrant un jeu agréable et amusant dans un univers visuel riche et coloré.'
                },
                'project14': {
                    'name': 'Gothenburg',
                    'technologies': 'Android Development',
                    'description': 'Nous avons créé cette application de guide de voyage pour la ville de Göteborg, comprenant des points d’intérêt, des restaurants, des hôtels, du positionnement GPS et des articles qui intéressent l’utilisateur, parmi un certain nombre d’autres caractéristiques essentielles et convaincantes dont un touriste curieux a toujours besoin.'
                },
                'project15': {
                    'name': 'Affligem',
                    'technologies': 'Sitecore, HTML, CSS, JavaScript',
                    'description': 'Le travail sur l’image de marque fut essentiel dans notre mission pour Affligem. Depuis la créa initiale jusqu’au lancement réussi du site, nous avons mis l’accent sur la richesse de l’histoire d’Affligem, ainsi que sur sa quête perpétuelle de la perfection dans le façonnage de ses bières raffinées.'
                },
                'project16': {
                    'name': 'Zukunf Läuft',
                    'technologies': 'Phonegap, Cordova, Angular.JS, Ionic',
                    'description': 'Cette application est basée sur un questionnaire scientifique qui aide les étudiants à gérer leurs attentes et leurs talents avec l\'objectif final de trouver la carrière qui leur convient le mieux. Nous avons programmé cet outil en mettant l\'accent sur la clarté, en particulier sur la manière dont les informations sont présentées à l\'utilisateur.'
                },
                'project17': {
                    'name': 'BraveZone',
                    'technologies': 'Drupal 7, HTML, CSS, JavaScript',
                    'description': 'Nous avons travaillé en étroite collaboration avec l\'équipe de BraveZone pour développer le portfolio de son site Web. C’est l’une des principales agences de publicité en Suède, et c’était une excellente expérience de faire équipe avec ses concepteurs et ses créateurs pour créer un nouveau portfolio innovant et singulier.'
                },
                'project18': {
                    'name': 'Médecins Sans Frontières',
                    'technologies': 'XML, PHP, MySQL, Apache, JavaScript',
                    'description': 'La mission que nous avons eu l’honneur et le privilège d’accomplir pour MSF durant de nombreuses années fut pour nous l’occasion d’atteindre un nouveau niveau d’excellence avec Drupal. C’est avec une grande fierté que nous avons pu aider MSF a évoluer avec succès dans un monde sans frontières numériques non plus.'
                },
                'project19': {
                    'name': 'GASAG',
                    'technologies': 'MVC 5, Sitecore 9.1.0, EXM (Integration with SAP), Multisite, Continues Integration - TeamCity, Octopus Deploy, HTML/HTML5, JavaScript, SCSS/CSS, Vue.JS, Gulp, Require.JS, JQuery, Node.JS for StoryBook, RestAPI, Docker',
                    'description': 'Droxic has worked with GASAG Group, which manages 10 websites and portals in total, serving both individual and business clients. Initially, Droxic’s main project aim was to fully revamp the main corporate website in 2020, followed by redesigning, enhancing, and implementing changes for the 10 separate portals in 2021.'
                },
                'project20': {
                    'name': 'MassUp',
                    'technologies': 'PHP, MySQL, Magento 2, HTML5, CSS3, jQuery, React.JS, JavaScript, LESS',
                    'description': 'MassUp, a provider of digital B2B insurance resell solutions, has collaborated with Droxic to upgrade their digital platform. The project aims to develop a robust Magento 2 system that integrates smoothly with various insurer systems. Droxic has successfully migrated MassUp’s old system to continue offering efficient and personalized insurance services and manage data migration seamlessly.'
                },
                'project21': {
                    'name': 'HMD',
                    'technologies': 'GraphQL, Typescript, React, Next.js, Node.js, Emotion, Contentful, Azure, Akamai, Varnish, Nginx, Hotjar, Open Graph, Web-vitals, Module Federation, Google Analytics, Google Optimize',
                    'description': 'HMD Global is an independent Finnish company, the only major European smartphone provider distributing Nokia phones and tablets to the world. In addition to devices and accessories, they offer a suite of services for enterprise customers. Working closely with the in-house HMD team, the Droxic team helped redesign and develop their new corporate website, and associated service portals for improved content, usability, engagement, and lead generation potential, with improved operations.'
                },
                'project22': {
                    'name': 'Siili',
                    'technologies': 'React.JS, JavaScript, TypeScript, Sitefinity, .NET',
                    'description': 'For several years now, Droxic has worked with Silli, a Finnish digital agency that collaborates with well-known brands in the marine, manufacturing, and various other industries. Our long-term partnership has developed complex web and mobile development solutions.'
                },
                'project23': {
                    'name': 'Open Talk',
                    'technologies': 'React Native, DevOps, React.JS, Flutter, Node.JS',
                    'description': 'Droxic has supported OpenTalk in developing and offering a SaaS solution for providers and other platforms, creating a POC and hybrid application, and implementing additional plugins to provide an optimized video platform solution and attract new users.'
                },
                'project24': {
                    'name': 'Fitogram',
                    'technologies': '.NET Core, React.JS',
                    'description': 'Droxic has participated in creating and developing an integrated platform that can be accessed and maintained easily on any device at any time. Our team has designed a complex microservice-based architecture to develop both the frontend (online booking of sports tools, videos on demand, and online courses) and the backend (video releases, attendance monitoring) of the platform. This has encouraged customer loyalty.'
                },
                'project25': {
                    'name': 'Quiet On',
                    'technologies': 'WordPress',
                    'description': 'Droxic has supported QuietOn with some modest tasks, including plugin updates and general maintenance. Recognizing some difficulties with the backend, Droxic overhauled it and switched to WPEngine hosting. After the rebuild, the functionalities were enhanced to boost sales, significantly improve performance, and reduce maintenance costs. This has resulted in greater value and a better user experience.'
                }
            },
            'news': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'News page title.',
                'frontPageDescription': 'Here we need a text for the News section.',

                'newsItem1': {
                    'title': 'Bees become harmful.',
                    'location': 'Berlin',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
                'newsItem2': {
                    'title': 'Immune cell tunnels.',
                    'location': 'Sofia',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
            }
        },
        'menu': {
            'about': 'À Propos',
            'work': 'Porfolio',
            'contact': 'Contact',
            'careers': 'Carrières',
            'blog': 'Blog',
            'droxicOod': '© 2025 Droxic | ',
            'policy': 'Charte de confidentialité',
            'logoTooltip': 'Accueil',
            'openMenuTooltip': 'Ouvrir le menu',
            'closeMenuTooltip': 'Fermer le menu'
        },
        'privacy': {
            'text': 'Nous utilisons des cookies pour améliorer votre expérience. En poursuivant votre navigation sur ce site, vous acceptez l\'utilisation de ces cookies. <a>Plus d\'informations</a>',
            'buttonText': 'D’accord.',
            'page': {
                content: `
                    <h1 class="page-content-common-heading">PROTECTION DES DONNÉES ET CHARTE EN MATIÈRE DE COOKIES</h1>

                    <div class="page-content-common-wrapper">
                        <section>
                            <p>Dernière mise à jour: le 25 mai 2018 / en vertu du Règlement Général sur la Protection des Données.</p>
                            <p>Cette charte de Protection des Données et des Cookies fournit des informations générales sur nous, notre charte de confidentialité et l'utilisation des cookies sur droxic.com (« site Web » / « page Web »).</p>
                        </section>

                        <section>
                            <h2>À PROPOS DE DROXIC LTD.</h2>
                            <p>Nous sommes Droxic Ltd., une société de droit bulgare, numéro au registre du commerce : BG202224741, et dont le siège social est situé à : Sofia, 62B rue Rodopski Izvor, Ét.1, Appt. 4 (« Droxic »). Nous comprenons et apprécions l’importance de vos données personnelles et leur protection dans le cadre de votre vie privée. Nous protègerons dûment l'intégrité de vos données personnelles, que ce soit vous concernant de manière indépendante ou en relation avec votre entreprise, vos services ou vos produits.</p>
                            <p>N'hésitez pas à nous contacter concernant vos données personnelles à l'adresse suivante: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>

                        <section>
                            <h2>COLLECTONS-NOUS VOS DONNÉES PERSONNELLES SUR NOTRE SITE WEB ?</h2>
                            <p>Nous ne cherchons généralement pas à collecter de données personnelles lors de l'utilisation de notre site Web. Nous n'avons pas de formulaire de contact et nous n'avons pas de formulaires d'inscription. Vos coordonnées de contact constituent des données à caractère personnel et, par conséquent, nous avons uniquement mentionné nos coordonnées de contact sans vous demander de données à caractère personnel spécifiques (telles que noms, e-mail, numéro de téléphone, etc.) Par conséquent, les informations que vous partagerez avec nous, si vous souhaitez entrer en contact, dépendent uniquement de votre décision, et nous les traiterons uniquement dans le but spécifique de répondre à votre demande.</p>
                            <p>Nous n'utiliserons vos données personnelles que pour notre correspondance, et celle-ci sera définitivement effacée à sa conclusion.</p>
                        </section>

                        <section>
                            <h2>UTILISONS-NOUS DES COOKIES ?</h2>
                            <p>Un cookie est un petit fichier texte qu'un site Web enregistre sur votre ordinateur ou votre appareil mobile lorsque vous visitez notre site Web. Droxic peut collecter des informations via des fichiers tels que des cookies ou des fichiers d’historiques sur les habitudes de navigation. Les cookies sont des fichiers du site Web qui sont stockés sur le navigateur Internet de l'utilisateur via un serveur Web pour la maintenance de la session de navigation en stockant l'adresse IP de l'utilisateur (de son ordinateur) et d'autres données de navigation possibles.</p>
                            <p>Droxic utilise uniquement des cookies de mesure d'audience Web. Nous les utilisons pour mesurer et analyser la navigation des utilisateurs sur <a href="http://www.droxic.com">www.droxic.com</a>. Les cookies d'analyse nous permettent de surveiller et d'analyser le comportement des utilisateurs sur notre site Web. L'analyse Web ne permet pas d'obtenir des informations privées telles que vos noms ou votre adresse électronique. Les seules informations obtenues concernent le nombre d'utilisateurs qui accèdent au site, le nombre de pages vues, la fréquence et la répétition des visites, leur durée, le navigateur Internet utilisé, l'opérateur fournissant le service, la langue, l'appareil utilisé ou la ville à laquelle l'adresse IP est attribuée.</p>
                            <p>Droxic utilise les cookies décrits via Google Analytics (un service fourni par Google Inc.) Comme indiqué ci-dessus, Google Analytics collecte les cookies de la première partie. Les informations collectées à l'aide de ces cookies sont envoyées à Google et sont utilisées pour évaluer la manière d'utilisation de notre page Web (à des fins de statistiques). Nous n'associerons aucune donnée collectée à quelque information d'identification personnelle que ce soit. Nous n'allons pas lier ou chercher à lier les informations des cookies avec une adresse IP ou l’identité d’un utilisateur d'ordinateur. Outre les fins d’’analyse et de statistiques, Droxic peut utiliser des cookies de publicité pour diffuser des annonces publicitaires qui, à notre avis, sont plus pertinentes pour vous et vos intérêts. Droxic utilise les services de Google et vous pouvez en savoir plus sur leurs politiques en consultant ces pages :</p>
                            <ul>
                                <li><p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a></p></li>
                                <li><p><a href="https://policies.google.com/privacy">Privacy Policy – Privacy & Terms – Google</a></p></li>
                            </ul>
                            <p>Vous pouvez contrôler ou supprimer les cookies si vous le souhaitez grâce aux options de votre navigateur Internet. Vous pouvez supprimer tous les cookies déjà présents sur votre ordinateur et configurer la plupart des navigateurs Internet pour empêcher leur installation. Nous devons alors vous informer que, dans un tel cas, cela pourrait vous empêcher de bénéficier pleinement de notre site Web.</p>
                        </section>
                        
                        <section>
                            <h2>AVONS-NOUS LE DROIT DE TRAITER DES DONNÉES PERSONNELLES ?</h2>
                            <p>Oui, nous respectons strictement les règles du Règlement Général sur la Protection des Données lors du traitement de données à caractère personnel. Le plus souvent, nous traitons des données personnelles sur les bases légales suivantes :</p>
                            <ul>
                                <li><p>Nous le faisons simplement parce que nous sommes légalement tenus de le faire - lorsque le traitement est nécessaire au respect d'une obligation légale.</p></li>
                                <li><p>Nous le faisons simplement, parce que nous ne pouvons pas accomplir nos obligations contractuelles sans le faire.</p></li>
                                <li><p>Nous le faisons aux fins des intérêts légitimes poursuivis par Droxic (par exemple : marketing).</p></li>
                                <li><p>Nous le faisons lorsque la personne concernée nous a accordé son consentement pour le faire.</p></li>
                            </ul>
                            <p>Nous pouvons également traiter des données à caractère personnel à une autre occasion, mais uniquement lorsque ce traitement est autorisé par des motifs juridiques spécifiques, comme indiqué dans le Règlement Général sur la Protection des Données.</p>
                            <p>Parfois, nous pouvons avoir accès aux données personnelles qui sont collectées par nos clients (les contrôleurs de données personnelles). Si Droxic agit en tant que responsable du traitement de données personnelles, Droxic suivra toujours les instructions de traitement données par le client ainsi que les obligations découlant du Règlement Général sur la Protection des Données.</p>
                        </section>
                        
                        <section>
                            <h2>COMMENT TRAITONS-NOUS LES DONNÉES PERSONNELLES DE NOS CANDIDATS AU TRAVAIL (RUBRIQUE « CARRIÈRES ») ?</h2>
                            <p>En soumettant vos données personnelles, vous consentez à ce que DROXIC les conserve et les utilise conformément à la présente charte en matière de protection des données et de cookies. Nous collectons et traitons les informations que vous fournissez lorsque vous postulez pour un emploi. De telles données personnelles comportent généralement : votre nom, votre adresse, votre adresse électronique, votre date de naissance, vos qualifications, votre expérience professionnelle, et, fondamentalement, tout ce que vous avez décidé d'inclure.</p>
                            <p>Nous pouvons également rechercher d'autres informations publiquement disponibles sur vous que vous avez publiés sur Internet (par exemple, via linkedin.com).</p>
                            <p>Nous nous fondons sur des bases légales pour traiter les données à caractère personnel des candidats – comme notre intérêt légitime à mener notre processus de recrutement, et nous les traitons également afin de prendre des mesures à la demande de la personne concernée avant la conclusion d'un contrat (contrat de travail).</p>
                            <p>Nous utilisons vos données personnelles pour : examiner votre candidature pour la position pour lequel vous avez postulé, ainsi que pour d'autres futures positions ouvertes chez Droxic. Par conséquent, nous pouvons conserver vos données personnelles jusqu'à 6 mois après leur réception.</p>
                            <p>Nous vous informons qu'à tout moment, vous pouvez demander et exiger que nous effacions vos données personnelles (généralement incluses dans votre CV et/ou lettre de motivation) et nous nous conformerons toujours à votre demande, sauf si vous êtes déjà employé chez Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>COMMENT TRAITONS-NOUS LES DONNÉES PERSONNELLES OBTENUES VIA <a href="http://www.linkedin.com">LINKEDIN.COM</a> ?</h2>
                            <p>Nos recruteurs en ressources humaines utilisent la plateforme sociale <a href="http://www.linkedin.com">linkedin.com</a> pour créer une réserve de talents dans le cadre du processus de recrutement, en ce qui concerne un poste actuellement à pourvoir, et aussi les postes à venir chez Droxic. Nous ne pouvons traiter que les informations publiquement disponibles que vous avez publiées / téléchargées : votre nom, votre expérience professionnelle, votre formation, vos compétences et certifications, vos réalisations, vos coordonnées de contact (courrier électronique ou lien vers le profil Linkedin).</p>
                            <p>Si nous considérons que vous êtes éligible à une position dans notre équipe, Droxic peut stocker les données personnelles que vous avez répertoriées sur votre profil Linkedin. Nous pouvons stocker de telles informations à l'aide des systèmes internes de Droxic ou via un logiciel tiers (à savoir, par exemple : <a href="http://www.podio.com">podio.com</a>, un service basé sur l'infonuagique appartenant à et géré par Citrix Systems Inc. : <a href="https://www.citrix.com/about/legal/privacy/">https://www.citrix.com/about/legal/privacy/</a>/). De telles données personnelles peuvent être conservées par Droxic pendant une période pouvant aller jusqu'à 3 ans après leur acquisition.</p>
                            <p>Chaque « candidat passif » que nous jugeons compétent est contacté par Droxic et dûment informé du traitement de ses données personnelles. Nous respectons la vie privée de chacun, et si vous préférez que nous cessions de traiter les données personnelles obtenues via <a href="http://linkedin.com">linkedin.com</a>com ou si vous souhaitez que nous cessions de vous contacter, veuillez en informer le recruteur de Droxic qui vous a contacté ou encore écrivez-nous à l'adresse suivante : <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a>. Pour effacer vos données personnelles, nous nous conformerons toujours à votre demande, sauf si vous êtes déjà employé chez Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>AVEZ-VOUS REÇU UNE LETTRE OU UN APPEL TÉLÉPHONIQUE DE NOUS QUE VOUS CONSIDÉREZ COMME DU «  MARKETING DIRECT» ?</h2>
                            <p>Nous pouvons parfois traiter des données à caractère personnel à des fins de marketing direct. Un tel traitement est soit fondé sur notre intérêt légitime à le faire, soit sur le consentement explicite de la personne concernée. Si vous ne souhaitez pas que nous continuions à vous contacter, n'hésitez pas à vous opposer à ce type de traitement en écrivant à : <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> ou en cliquant simplement sur le lien « Se désabonner ». Nous nous conformerons toujours à ces demandes !</p>
                        </section>
                        
                        <section>
                            <h2>COMMENT PROTÉGEONS-NOUS LES DONNÉES PERSONNELLES FOURNIES PAR NOS CLIENTS ?</h2>
                            <p>Nous nous engageons à assurer la sécurité et la protection des données personnelles fournies par nos clients. Afin de prévenir tout accès non autorisé ou toute divulgation, nous avons mis en place des procédures physiques, électroniques et de gestion appropriées pour protéger et sécuriser les informations que nous collectons, tant les données à caractère personnel, que les secrets relatifs au droit commercial.</p>
                            <p>En vertu de l'article 28 du Règlement Général sur la Protection des Données, nous demandons qu'un accord explicite contrôleur-responsable du traitement soit signé. Nous sommes déterminés à fournir des garanties suffisantes pour mettre en œuvre les mesures techniques et organisationnelles appropriées de manière à ce que le traitement satisfasse aux exigences du présent règlement et garantisse la protection des droits de la personne concernée. Ces garanties varieront en fonction de l’importance, du format, de l'emplacement, de la quantité, de la distribution et du stockage des données à caractère personnel, et comprendront des mesures destinées à protéger les données à caractère personnel contre tout accès non autorisé.</p>
                        </section>
                        
                        <section>
                            <h2>COMBIEN DE TEMPS CONSERVONS-NOUS LES DONNÉES PERSONNELLES ?</h2>
                            <p>Les délais de conservation des données à caractère personnel dépendent du type d'informations, des finalités pour lesquelles celles-ci sont utilisées et encore d'autres facteurs.</p>
                            <p>En général, nous conserverons vos informations personnelles pendant une période raisonnablement nécessaire pour atteindre les objectifs pour lesquels celles-ci ont été traitées et au-delà (à moins que la loi n’exige ou ne permette une durée de conservation plus longue). Nous conserverons et utiliserons également vos informations aussi longtemps que nécessaire pour résoudre les litiges et/ou faire respecter nos droits et accords.</p>
                        </section>
                        
                        <section>
                            <h2>QUELS DROITS AVEZ-VOUS ?</h2>
                            <p>Le Règlement Général sur la Protection des Données vous confère de nombreux droits sur vos données personnelles : droit d'accès, de correction, d'effacement, de limitation du traitement, d'opposition et de portabilité des données, demande d'une copie de vos données personnelles ou, si vous n'êtes pas satisfait, vous pouvez même déposer une plainte auprès de l'Autorité de Protection des Données : https://www.cpc.bg/. Nous ne vous demanderons pas de payer de frais pour accéder à vos informations personnelles ou pour exercer l'un des autres droits, toutefois, nous pouvons facturer des frais raisonnables si vos demandes sont clairement non fondées ou excessives.</p>
                            <p>Toute question ou demande relative à cette charte doit être adressée à : <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>
                        
                        <section>
                            <h2>MODIFICATIONS DE CETTE CHARTE SUR LA PROTECTION DES DONNÉES ET LES COOKIES</h2>
                            <p>Droxic se réserve le droit de modifier et de mettre à jour cette charte à tout moment. Nous pouvons également informer les personnes concernées ou nos clients si nous apportons des mises à jour substantielles.</p>
                        </section>
                    </div>
                `
            }
        }
    },
    'no': {
        'pages': {
            'home': {
                'slides': {
                    'aboutTitle': 'Det er Droxic.',
                    'aboutSubtitle': 'Vi skaper digitale opplevelser.',
                    'aboutButtonText': 'Utforske',
                    'clientsTitle': 'Kunder.',
                    'clientsSubtitle': 'Globale merkevarer, livlige startups og andre byråer.',
                    'clientsButtonText': 'Se kunder',
                    'expertiseTitle': 'Hva vi er om.',
                    'expertiseSubtitle': 'Det digitale er vår lekeplass og lidenskap.',
                    'expertiseButtonText': 'Våre evner',
                    'contactsTitle': 'Ubegrenset rekkevidde.',
                    'contactsSubtitle': 'Vi er virkelig globale med kontorer og venner overalt.',
                    'contactsButtonText': 'Kontakt'
                },
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digitale plattformer.',
                        'digitalPlatformsSubtitle': 'Vi koder nettsider og digitale opplevelser som kobler merkevarer med mennesker.',
                        'mobileApplicationsTitle': 'Mobilapplikasjoner.',
                        'mobileApplicationsSubtitle': 'Vi leverer native og hybrid mobilapps for hvert større operativsystem.',
                        'creativeDirectionsTitle': 'Kreativ retning.',
                        'creativeDirectionsSubtitle': 'Hvert prosjekt blir veiledet av en klar og kreativ vei, unik for vår klient.',
                        'brandingTitle': 'Merkevarebygging.',
                        'brandingSubtitle': 'Vår kode, design og tekstskriving bygger sterke merkevarer ved hjelp av forskning og strategi.'
                    }
                }
            },
            'about': {
                'description': "Vi er et proaktivt digitalt byrå med krav for presisjon, en partner for byråer og kunder over hele verden som skaper merkevareopplevelser på web og mobil.",
                'expertiseTitle': 'Ekspertise.',
                'expertiseSubtitle': 'Digital: Web & Mobil.',
                'expertiseButtonText': 'Hva vi gjør',
                'teamTitle': 'Team.',
                'teamSubtitle': 'Tenkere. Skapere. Sammen.',
                'teamButtonText': 'Hvem vi er',
                'clientsTitle': 'Kunder.',
                'clientsSubtitle': 'Vi er ingenting uten våre kunder.',
                'clientsButtonText': 'Se kunder.',
                'referencesTitle': 'Erfaring.',
                'referencesSubtitle': 'Se vår suksess førstehånds.',
                'referencesButtonText': 'Våre historier',
                'dialogs': {
                    'expertise': {
                        'digitalPlatformsTitle': 'Digitale plattformer.',
                        'digitalPlatformsSubtitle': 'Vi koder nettsider og digitale opplevelser som kobler merkevarer med mennesker.',
                        'mobileApplicationsTitle': 'Mobilapplikasjoner.',
                        'mobileApplicationsSubtitle': 'Vi leverer native og hybrid mobilapps for hvert større operativsystem.',
                        'creativeDirectionsTitle': 'Kreativ retning.',
                        'creativeDirectionsSubtitle': 'Hvert prosjekt blir veiledet av en klar og kreativ vei, unik for vår klient.',
                        'brandingTitle': 'Merkevarebygging.',
                        'brandingSubtitle': 'Vår kode, design og tekstskriving bygger sterke merkevarer ved hjelp av forskning og strategi.'
                    },
                    'team': {
                        'pavelName': 'Pavel',
                        'pavelPosition': 'CEO',

                        'ilianName': 'Ilian',
                        'ilianPosition': 'CTO',

                        'chopyName': 'Chopy',
                        'chopyPosition': 'CPO',

                        'stoyanName': 'Stoyan',
                        'stoyanPosition': 'CPP',

                        'mariName': 'Mari',
                        'mariPosition': 'Business development',

                        'georgeName': 'George',
                        'georgePosition': 'Business development',

                        'sevdaName': 'Sevda',
                        'sevdaPosition': 'Business development',

                        'arminName': 'Armin',
                        'arminPosition': 'Business development',

                        'joostName': 'Joost',
                        'joostPosition': 'Business development',

                        'richardName': 'Richard',
                        'richardPosition': 'Business development',

                        'viliName': 'Vili',
                        'viliPosition': 'Resource Manager',

                        'zoriName': 'Zori',
                        'zoriPosition': 'Account executive',

                        'loraName': 'Lora',
                        'loraPosition': 'HR Manager',

                        'veronikaName': 'Veronika',
                        'veronikaPosition': 'HR Generalist',

                        'ivaName': 'Iva',
                        'ivaPosition': 'HR Generalist',

                        'natName': 'Nat',
                        'natPosition': 'HR Generalist',

                        'borislavaHrName': 'Borislava',
                        'borislavaHrPosition': 'HR Manager',

                        'hristinaName': 'Hristina',
                        'hristinaPosition': 'Head of Marketing',

                        'julianName': 'Julian',
                        'julianPosition': 'Project manager',

                        'valeriaName': 'Valeria',
                        'valeiraPosition': 'Project manager',

                        'alexName': 'Alex',
                        'alexPosition': 'UX/UI',

                        'konstantinName': 'Konstantin',
                        'konstantinPosition': 'Software engineer',

                        'daniName': 'Dani',
                        'daniPosition': 'Software engineer',

                        'galinName': 'Galin',
                        'galinPosition': 'Software engineer',

                        'kirilName': 'Kiril',
                        'kirilPosition': 'Software engineer',

                        'lyuboName': 'Lyubo',
                        'lyuboPosition': 'Software engineer',

                        'emiName': 'Emi',
                        'emiPosition': 'Software engineer',

                        'zdravkoName': 'Zdravko',
                        'zdravkoPosition': 'Software engineer',

                        'radovanName': 'Radovan',
                        'radovanPosition': 'Branch manager',

                        'tsvetoName': 'Tsveto',
                        'tsvetoPosition': 'Software engineer',

                        'krisiName': 'Krisi',
                        'krisiPosition': 'Software engineer',

                        'borislavaName': 'Borislava',
                        'borislavaPosition': 'Software engineer',

                        'ivanvName': 'Ivan V',
                        'ivanvPosition': 'Software engineer',

                        'kristianName': 'Kris',
                        'kristianPosition': 'Software engineer',

                        'slavName': 'Slav',
                        'slavPosition': 'Software engineer',

                        'dimitarName': 'Dimitar',
                        'dimitarPosition': 'Software engineer',

                        'tsvetanName': 'Tsvetan',
                        'tsvetanPosition': 'Software engineer',

                        'dautName': 'Daut',
                        'dautPosition': 'Software engineer',

                        'dimiName': 'Dimi',
                        'dimiPosition': 'QA Specialist',

                        'bojoName': 'Bojo',
                        'bojoPosition': 'QA Specialist',

                        'foriName': 'Fori',
                        'foriPosition': 'QA Specialist',

                        'chochoName': 'Chocho',
                        'chochoPosition': 'QA Specialist',

                        'vladiName': 'Vladi',
                        'vladiPosition': 'Sys Admin',

                        'saraName': 'Sara',
                        'saraPosition': 'Office Manager',

                        'valetoName': 'Valentina',
                        'valetoPosition': 'Office Manager',

                        'kristinaSavovaName': 'Kristina',
                        'kristinaSavovaPosition': 'Office Manager',

                        'dobriName': 'Dobri',
                        'dobriPosition': 'Software engineer',

                        'dusanName': 'Dušan',
                        'dusanPosition': 'Software engineer',

                        'darkoName': 'Darko',
                        'darkoPosition': 'Software engineer',

                        'ivanchName': 'Ivan',
                        'ivanchPosition': 'Software engineer',

                        'dimitardName': 'Dimitar',
                        'dimitardPosition': 'Software engineer',

                        'milenaName': 'Milena',
                        'milenaPosition': 'Office Manager Niš',

                        'danielaName': 'Daniela',
                        'danielaPosition': 'Account Executive Assistant',

                        'ninaName': 'Nina',
                        'ninaPosition': 'Software engineer',

                        'vanyoName': 'Vanyo',
                        'vanyoPosition': 'UX/UI',

                        'petarName': 'Petar',
                        'petarPosition': 'Software engineer',

                        'biljanaName': 'Biljana',
                        'biljanaPosition': 'Software engineer',

                        'miroljubName': 'Miroljub',
                        'miroljubPosition': 'Software engineer',

                        'radosName': 'Radoš',
                        'radosPosition': 'Software engineer',

                        'dejanName': 'Dejan',
                        'dejanPosition': 'QA specialist',

                        'lazaName': 'Laza',
                        'lazaPosition': 'Software engineer',

                        'aleksandarName': 'Aleksandar',
                        'aleksandarPosition': 'Software engineer',

                        'markoName': 'Marko',
                        'markoPosition': 'Software engineer',

                        'yanaName': 'Yana',
                        'yanaPosition': 'Project manager',

                        'boyanName': 'Boyan',
                        'boyanPosition': 'Project manager',

                        'ivanDimitrovName': 'Ivan',
                        'ivanDimitrovPosition': 'Software engineer',

                        'stefanName': 'Stefan',
                        'stefanPosition': 'Software engineer',
                    },
                    'references': {
                        'realityBytesName': 'Andreas  Kuno  Kuntze',
                        'realityBytesPosition': 'CEO',
                        'realityBytesCompany': 'Reality  Bytes  Neue  Medien',
                        'realityBytesDescription': 'Fra og med to år fra nå har Droxic-teamet blitt en sømløs forlengelse av vår produksjon. Vårt partnerskap har vokst til full-stack utviklingstjenester. De er alltid i forkant av teknologisk innovasjon. Jeg kan stole fullt ut på dem.',


                        'lieferheldName': 'Matthias Pfaff',
                        'lieferheldPosition': 'Head of customer experience',
                        'lieferheldCompany': 'Lieferheld',
                        'lieferheldDescription': 'Jeg hadde utviklere fra Droxic som jobbet i teamene mine for Android, iOS, Python og JS-utvikling. Alle deres folk var veldig hyggelige, kompetente og morsomme å jobbe med. Droxic som selskap var alltid super pålitelig og ga mye fleksibilitet som partner. Jeg vil alltid anbefale dem til alle som trenger utviklingsstøtte i prosjektene eller produktene sine.',


                        'oliverUkName': 'Martin  Nartey',
                        'oliverUkPosition': 'Digital  Project  Lead',
                        'oliverUkCompany': 'Oliver  UK',
                        'oliverUkDescription': "Droxics digitale ekspertise er uten sidestykke. Da vi jobbet med dem på et utstillingsvindu digitalt prosjekt for en av våre største kunder, overgikk de forventningene til alle kravene vi sendte over, og de var ekstremt fleksible og kunne planlegge rundt våre prosjektbehov. De hjalp med å bringe organisasjonen til et vanskelig prosjekt, og vår kunde var helt fornøyd med det endelige produktet.",


                        'kweakName': 'Baris  Karatas',
                        'kweakPosition': 'CTO',
                        'kweakCompany': 'Kweak',
                        'kweakDescription': "Jeg vil gjerne fremheve vår takknemlighet for det pålitelige samarbeidet og ferdighetene teamet deres brakte til vårt prosjekt. Det var en flott reise, og jeg vil absolutt anbefale Droxic og det flotte teamet til andre. Håper å komme i kontakt med dere igjen i fremtiden.",


                        'exposureName': 'Rory Oliver',
                        'exposurePosition': 'Production Director',
                        'exposureCompany': 'Exposure UK',
                        'exposureDescription': 'Jeg jobbet med Droxic mens jeg var produksjonsdirektør hos Exposure, og jeg kan ærlig si at de er den beste eksterne utvikleren jeg noen gang har jobbet med. Vi startet med dem med et middels, men ekstremt høyprofilert prosjekt, og de leverte over forventningene. I løpet av de neste 2 årene jobbet vi med en rekke prosjekter, og hver gang var kvalitet, service og levering i toppklasse. Jeg ville ikke nøle med å bruke dem igjen. De er også flotte folk og en glede å jobbe med.',


                        'icaewName': 'Adam  Thompson',
                        'icaewPosition': 'UX  Manager',
                        'icaewCompany': 'ICAEW',
                        'icaewDescription': "Vi begynte å bruke Droxic for å supplere vår UK-baserte utviklingsressurs og har vært imponert over både kvaliteten på arbeidet deres og deres fleksibilitet for å skalere opp og ned antallet utviklere som jobber med prosjektene våre på kort varsel.",


                        'siiliName': 'Matti Kiviluoto',
                        'siiliPosition': 'CMS Team Lead',
                        'siiliCompany': 'Siili Solutions Plc',
                        'siiliDescription': "Vi har vært ekstremt fornøyde med profesjonaliteten og kompetansen til utviklerne vi har jobbet med, og vårt forretningsforhold har alltid vært smidig, transparent og rettferdig. Vi har gjentatte ganger blitt overrasket over Droxics evne til å levere nye høyt kvalifiserte utviklere innen så forskjellige utviklingsfelt. Jeg kan oppriktig anbefale Droxic som en programvareutviklingspartner.",


                        'initName': 'Klaus Santel',
                        'initPosition': 'Director Development',
                        'initCompany': 'Init',
                        'initDescription': "I mange år har Droxic vært en pålitelig partner når det gjelder å støtte våre webprosjekter. De er veldig fleksible og alltid forpliktet til å møte forventningene våre. Droxic har hjulpet oss i mange kritiske prosjektsituasjoner og ressursmangel raskt og kompetent, det er alltid en glede å jobbe med dem. Kommunikasjonen er eksemplarisk, og i tilfelle problemer fant vi alltid en løsning sammen veldig raskt."
                    }
                }
            },
            'careers': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'Vi søker talent.',
                'frontPageDescription': 'Skapere overalt, fortsett å rulle ned for å se mulighetene.',
                'wantToJoinTitle': 'Join us.',
                'applyNowButton': 'Apply',
                'gdprText': 'By applying to this position, I give my consent that the personal data included in my CV/resume/motivational letter will be processed in accordance with Droxic\'s Data Protection Policy and for the mentioned period of time (<a>Data Protection Policy</a>)',

                'jobOffer1': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Sitecore Engineer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as a Sitecore Engineer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Sitecore architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with Sitecore development tools and CI/CD pipelines.',
                    'requiredSkillsListItem4': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem5': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem6': 'Excellent problem-solving skills and attention to details.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Develop, implement, and maintain Sitecore-based web applications.',
                    'responsibilitiesListItem2': 'Collaborate with cross-functional teams to define, design, and ship new features.',
                    'responsibilitiesListItem3': 'Optimize applications for maximum speed and scalability',
                    'responsibilitiesListItem4': 'Troubleshoot and debug existing applications, ensuring a high-performance user experience.',
                    'responsibilitiesListItem5': 'Stay updated with the latest industry trends and best practices related to Sitecore.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer2': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Senior Front-end Expert.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'JavaScript is a children’s’ toy for you.',
                    'requiredSkillsListItem2': 'You have extensive experience in Front-End / Full Stack web development.',
                    'requiredSkillsListItem3': 'You have particular affinity for React.js and/or Vue.js, and have participated in software projects using at least one of these technologies.',
                    'requiredSkillsListItem4': 'You have solid knowledge in CSS3 (including SASS/LESS) and HTML5.',
                    'requiredSkillsListItem5': 'You are always open to expanding your knowledge and can easily pick up on a new framework.',
                    'requiredSkillsListItem6': 'You have proven experience using responsive design principles.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will develop sophisticated visual and functional front-end components.',
                    'responsibilitiesListItem3': 'You will build reusable, scalable code; following best practices.',
                    'responsibilitiesListItem4': 'You will work with some of the hottest technologies around.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer3': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Business Development Manager.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'A few years of progressive selling experience to international clients in the digital field.',
                    'requiredSkillsListItem2': 'Working knowledge of the web and mobile development services landscape.',
                    'requiredSkillsListItem3': 'Existing network of end client relationships in Western Europe.',
                    'requiredSkillsListItem4': 'Self-starter, excellent negotiator and a team player.',
                    'requiredSkillsListItem5': 'Excellent presentation, phone and written communication skills.',
                    'requiredSkillsListItem6': 'Highly proficient in English.',
                    'requiredSkillsListItem7': 'Second language proficiency - Swedish/Norwegian/Danish/Finnish or German.',
                    'requiredSkillsListItem8': 'Demonstrated passion for web and mobile innovations.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Aim for growth in market share, volume, turnover and profit.',
                    'responsibilitiesListItem2': 'Approach new prospects and offer Droxic’s services.',
                    'responsibilitiesListItem3': 'Generate new relationships with prospective clients.',
                    'responsibilitiesListItem4': 'Understand customer needs and highlight the features and the benefits of our quality-oriented services.',
                    'responsibilitiesListItem5': 'Work close with internal software development department and PMs to develop strategies and offers that meet the brief of the client.',
                    'responsibilitiesListItem6': 'Identifying further opportunities to serve the existing clients.',
                    'responsibilitiesListItem7': 'Maintain up to date knowledge on trends, issues and opportunities within territory.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer4': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Java senior developer.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have practical experience in JSE based web development technologies.',
                    'requiredSkillsListItem2': 'You have practical experience in Spring & Spring Boot.',
                    'requiredSkillsListItem3': 'You have knowledge in CI/CD methodology / tooling (Maven, Jenkins, GitLab-CI/CD, Bamboo).',
                    'requiredSkillsListItem4': 'You have knowledge in container technologies (Docker, Kubernetes, OpenShift/OKD).',
                    'requiredSkillsListItem5': 'You have knowledge in relational Databases (MySQL, Postgres, Oracle).',
                    'requiredSkillsListItem6': 'Knowledge in requirements engineering / technical specifications / scope management.',
                    'requiredSkillsListItem7': 'You have knowledge in architectural approaches.',
                    'requiredSkillsListItem8': 'You have experience in agile methodologies.',
                    'requiredSkillsListItem9': 'It’s a plus if you have knowledge in architectural approaches.',
                    'requiredSkillsListItem10': 'It’s a plus if you have experience in software testing technologies.',
                    'requiredSkillsListItem11': 'It’s a plus if you have knowledge in customizing Content Management Systems (esp. CoreMedia CMS).',
                    'requiredSkillsListItem12': 'It’s a plus if you have knowledge in further Java frameworks (JEE, Quarkus, Wicket, Camunda, Keycloak, form.io, JSF).',
                    'requiredSkillsListItem13': 'You have good written and spoken language skills (English).',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer5': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Application Support Specialist.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have minimum 3-5 years of experience in technical consulting and analytics.',
                    'requiredSkillsListItem2': 'You have good understanding of the issues that an end-user faces with corporate IT Service Management.',
                    'requiredSkillsListItem3': 'You have analytical approach to recurring problems and collected data.',
                    'requiredSkillsListItem4': 'You have good communication skills and ability to maintain good relationship with customers.',
                    'requiredSkillsListItem5': 'You have positive attitude and great interpersonal skills, with a “can do” attitude.',
                    'requiredSkillsListItem6': 'You have excellent written and spoken English language skills.',
                    'requiredSkillsListItem7': 'It’s an advantage if you have relevant software industry experience in any of the following: IT systems, enterprise or infrastructure management, application development and management, security, and/or analytics.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will assist with general inquiries, product support, and troubleshooting via phone, e-mail, and chat.',
                    'responsibilitiesListItem2': 'You will track customer issues using a ticketing system to ensure quick resolution and proper follow-up and documentation.',
                    'responsibilitiesListItem3': 'You will collect data and draw conclusions from it, identifying problems and suggest resolutions.',
                    'responsibilitiesListItem4': 'You will analyze customer needs, goals, strategic plans and constraint in order to advice the best possible use of the platform.',
                    'responsibilitiesListItem5': 'You will have to understand Customer Digital Experience goals, strategic plans and constraints in order to advise on the best use of the product.',
                    'responsibilitiesListItem6': 'You will stay current on product development/releases to a level required for the above activities.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer6': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Project Manager.',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'At least 2-3 years of technical delivery experience with a demonstrated PM track record in web and mobile projects.',
                    'requiredSkillsListItem2': 'Full understanding of how to manage an end-to-end technical  project lifecycle, from idea inception through development and launch.',
                    'requiredSkillsListItem3': 'Demonstrated experience simultaneously working on multiple projects with multiple partners.',
                    'requiredSkillsListItem4': 'Experience with both Waterfall and Agile.',
                    'requiredSkillsListItem5': 'Experience facilitating rapid application development teams to deliver technology solutions on time, on budget, and according to client requirements.',
                    'requiredSkillsListItem6': 'Experience using an agile delivery management platform, JIRA preferred.',
                    'requiredSkillsListItem7': 'Fluent English language.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Directing and coordinating multiple web and mobile projects from initiation phase through to implementation phase.',
                    'responsibilitiesListItem2': 'Translation of business requirements into technology solutions and participation in the analysis, evaluation and choice of development toolchains.',
                    'responsibilitiesListItem3': 'Working closely with cross-functional teams in order to define scope, goals and deliverables in alignment with our clients\' goals and our business strategy.',
                    'responsibilitiesListItem4': 'Project estimations and resource planning.',
                    'responsibilitiesListItem5': 'Tracking and reporting on progress. Aiming for delivery of quality solutions on time.',
                    'responsibilitiesListItem6': 'Proactively managing changes and improvements in project scope, identifying issues and driving for resolution.',
                    'responsibilitiesListItem7': 'Managing relationships with new clients and partners.',
                    'responsibilitiesListItem8': 'Organizing and facilitating of daily stand-ups and retrospectives at the end of each sprint. Creating an environment in which the team is continuously learning and encouraged to reflect and improve their delivery capacity.',
                    'responsibilitiesListItem9': 'Coordinating communication between teams and facilitating productive discussions.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer7': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Data Engineer.',
                    'location': 'Sofia',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have 2+ years of industry data engineering experience, including experience in ETL design, implementation and maintenance.',
                    'requiredSkillsListItem2': 'You have proven experience in the data warehouse space, as well as schema design.',
                    'requiredSkillsListItem3': 'You are very good in SQL and at least one modern programming language (Python, Java, etc).',
                    'requiredSkillsListItem4': 'You have profound knowledge of Kubernetes.',
                    'requiredSkillsListItem5': 'You are experienced in designing data solutions within cloud platforms such as AWS (preferred), Google Cloud Platform or Azure.',
                    'requiredSkillsListItem6': 'You have strong knowledge and experience with some distributed data processing technology like Redshift/BigQuery/Snowflake/Spark or similar.',
                    'requiredSkillsListItem7': 'Bonus: If you have experience with Streaming data AWS Kinesis, Kafka or RabbitMQ.',
                    'requiredSkillsListItem8': 'Bonus: if you have Bachelor’s degree in a technical field (computer science, engineering, mathematics,  informatics); advanced degree preferred (or equivalent experience).',
                    'requiredSkillsListItem9': 'Bonus: if you have knowledge of visualisation tools such as Looker, Tableau, etc.',
                    'requiredSkillsListItem10': 'Bonus: if you have knowledge of Snowplow.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will interface with engineers, product managers and data scientists to understand data needs and implement robust and scalable solutions.',
                    'responsibilitiesListItem2': 'You will work directly with analytics engineers to implement robust and reusable data models.',
                    'responsibilitiesListItem3': 'You will build and maintain data pipelines.',
                    'responsibilitiesListItem4': 'You will ensure data quality and accessibility for all types of data.',
                    'responsibilitiesListItem5': 'You will design and implement ETL processes for new and existing data sources.',
                    'responsibilitiesListItem6': 'You will identify and rectify data quality issues.',
                    'responsibilitiesListItem7': 'You will import data from APIs and several different data sources.',
                    'responsibilitiesListItem8': 'You will write good documentation—for other data engineers, as well as for other colleagues.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },

                'jobOffer8': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Full stack developer.',
                    'location': 'Sofia / Niš',

                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'You have experience with JavaScript, Vue.js 2 & 3 and VueX.',
                    'requiredSkillsListItem2': 'You have experience with Nest.js and Python Flask.',
                    'requiredSkillsListItem3': 'You have experience with PostgreSQL, TypeORM.',
                    'requiredSkillsListItem4': 'You have experience with Redis, Kafka, Kubernetes.',
                    'requiredSkillsListItem5': 'You speak English fluently.',

                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'You will participate in interesting and challenging client projects, working both independently and as part of a team.',
                    'responsibilitiesListItem2': 'You will build reusable, scalable code, following best practices.',
                    'responsibilitiesListItem3': 'You will work with some of the hottest technologies around.',

                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side projects.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                },
                'jobOffer9': {
                    'detailsButtonLabel': 'View more',
                    'title': 'Umbraco Engineer',
                    'location': 'Sofia',


                    'requiredSkillsTitle': 'Is this you?',
                    'requiredSkillsListItem1': 'Proven experience as an Umbraco Developer or a similar role.',
                    'requiredSkillsListItem2': 'Strong understanding of Umbraco CMS, including architecture, modules, and best practices.',
                    'requiredSkillsListItem3': 'Experience with MVC, .NET, and web services.',
                    'requiredSkillsListItem4': 'Familiarity with front-end technologies (HTML, CSS, JavaScript).',
                    'requiredSkillsListItem5': 'Excellent analytical skills with a keen eye for detail.',


                    'responsibilitiesTitle': 'Your role.',
                    'responsibilitiesListItem1': 'Design, develop, and maintain Umbraco-based websites and applications.',
                    'responsibilitiesListItem2': 'Work closely with project managers, designers, and other developers to create high-quality solutions.',
                    'responsibilitiesListItem3': 'Ensure the performance, quality, and responsiveness of applications.',
                    'responsibilitiesListItem4': 'Troubleshoot and resolve issues in existing applications.',
                    'responsibilitiesListItem5': 'Stay current with emerging trends and technologies related to Umbraco development.',


                    'weOfferTitle': 'The opportunity.',
                    'weOfferListItem1': 'Exciting and challenging client-side project.',
                    'weOfferListItem2': 'Great remuneration and social benefits.',
                    'weOfferListItem3': 'A friendly working environment and open company culture.',
                    'weOfferListItem4': 'Work in a cozy office with a private garden.',
                    'weOfferListItem5': 'Flexible working time.',
                    'weOfferListItem6': 'And many more...',
                }
            },
            'work': {
                'project1': {
                    'name': 'Lieferheld',
                    'technologies': 'HTML5, CSS3, JavaScript, jQuery, Backbone.JS, React.JS, NodeJS, Immutable.JS, ExpressJS, PhoneGap, PostgreSQL, Elastic search, Django, Jenkins',
                    'description': 'I tre år har vi hjulpet denne verdensledende matleveringsselskapet med dens front-end og back-end bestrebelser. Vårt langvarige forhold har utviklet komplekse digitale plattformer for merkevaren, og håndtert alle verdensomspennende representanter. '
                },
                'project2': {
                    'name': 'ICAEW',
                    'technologies': 'Sitecore CMS',
                    'description': 'Vi er stolte av å ha fått ansvaret for den pågående vedlikehold og utvikling av nye funksjoner for den anerkjente profesjonelle organisasjonen for regnskapsførere som inkluderer mer enn 150,000 medlemmer rundt om i verden.'
                },
                'project3': {
                    'name': 'SupperApps',
                    'technologies': 'Linux, Apache, MySQL and PHP (LAMP), Symfony 2, Custom-built CMS system, PhoneGap, Ajax',
                    'description': 'Vi har utviklet og vedlikeholdt tre mobile applikasjoner (Klienter, Kokker, og Sjåfører), som er en plattform for hjemmelaget og sunne alternativer for måltid. Alt blir håndtert av et samlet CMS, utviklet med et kjernefokus på brukeropplevelsen.'
                },
                'project4': {
                    'name': 'IAM',
                    'technologies': 'Microsoft.NET, C #, ASP.NET, Sitefinity CMS, IIS, Grunt, SASS, Bootstrap, JSON, GoogleMaps API',
                    'description': 'Vi har utviklet hovednettsted, en nettbutikk og flere mikronettsteder, for Institute of Advanced Motorists. Høye nivåer av personlig kundeopplevelse og kundens autonomi var nøkkelmål da vi skapte plattformen.'
                },
                'project5': {
                    'name': 'Ally',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Allly-appen hjelper kundene med å planlegge turene sine på den mest praktiske måten, ved å sammenligne pris, tid og transportmidler. Vår utvikling ble rettet mot å sikre at alle disse faktorene kom sammen og forenklet kundeopplevelsen.'
                },
                'project6': {
                    'name': 'Mamut',
                    'technologies': 'Wordpress, HTML, CSS, JavaScript',
                    'description': 'Mamut er et unikt digitalt eventyr som gir besøkende tilgang til verdens mest slående fjell. Vår utvikling måtte være like eventyrlig som visjonen, og vi lyktes med prosjekt 360 som brakte det ekstreme til folks skjermer.'
                },
                'project7': {
                    'name': 'Raiffeisen Bank',
                    'technologies': 'Dojo, jQuery, AJAX, NPM/Bower, Grunt, SASS/SCSS, Bootstrap/ZURB,  Foundation, Handlebars, jQuery, jQuery UI, Classy, Require.js',
                    'description': 'Konseptet med dette prosjektet var å tilby en personlig hub som svarer på kundens behov med et grafisk system som støtter to kommunikative nivåer: emosjonelle og informative, med slående fotografering og et brukervennlig grensesnitt.'
                },
                'project8': {
                    'name': 'Mr & Ms Smith',
                    'technologies': 'PHP, Symfony, Zend 2',
                    'description': 'Vår utvikling og pågående vedlikehold av Mr & Ms Smith har sett den utvikle seg fra sine beskjedne røtter som en bestillingsplattform i 2003 til den globale reiseklubben den er i dag med over en million medlemmer i mer enn hundre land.'
                },
                'project9': {
                    'name': 'Le Mont Botanique',
                    'technologies': 'Drupal 7 E-commerce, HTML, CSS, JavaScript',
                    'description': 'Vår e-shop revolusjonerte bestillingsprosessen, forbedret kundeopplevelsen fra første visning til utsjekk. Strukturen på nettstedet er fullt håndterlig med regnskap, utskrift av rapporter og fakturaer, spore opptegnelser og flerspråklig støtte.'
                },
                'project10': {
                    'name': 'Bubble Nova',
                    'technologies': 'Cocos2d',
                    'description': 'Vår utvikling for dette lekne prosjektet tok de unike grafikkene og karakterene fra Bubble Nova og skapte et eventyrspill som tar spillere gjennom en rekke forskjellige soner og nivåer.'
                },
                'project11': {
                    'name': 'CalConvert',
                    'technologies': 'iOS Development, Swift, PHP, JSON',
                    'description': 'Denne appen er en gratis kalkulator samt en valuta- og enhetsomform for iOS og WatchOS. Dens klassiske design fokuserer på funksjonalitet og brukeropplevelse, for mennesker i alle aldre med en tilpassbar mulighet for fargerike skinn.'
                },
                'project12': {
                    'name': 'Timer',
                    'technologies': 'iOS Development, Swift',
                    'description': 'Timer er en app som hjelper en person med å optimalisere og organisere tiden sin. Vi har utviklet den smarte appen slik at folk overalt kan sette pålitelige tidtakere, påminnelser, nedtellinger og alarmer, og også opprette gjøremålslister og håndtere oppgaver.'
                },
                'project13': {
                    'name': 'Spooky Hill Game',
                    'technologies': 'Cocos2d',
                    'description': 'Spooky Hill er et raskt arkadespill designet og utviklet av Droxic-mobilteamet. Vårt overordnede mål gjennom dette selvinitierte prosjektet var å underholde spillere i feriene med kontinuerlig oppdaterte karakterer og endeløs moro.'
                },
                'project14': {
                    'name': 'Gothenburg',
                    'technologies': 'Android Development',
                    'description': 'Vi har laget denne reiseguide-appen for byen Göteborg, inkludert severdigheter, restauranter, hoteller, GPS-posisjonering og artikler av interesse for brukeren, blant annet en rekke andre viktige og overbevisende funksjoner som en turist ville trenge og sette pris på.'
                },
                'project15': {
                    'name': 'Affligem',
                    'technologies': 'Sitecore, HTML, CSS, JavaScript',
                    'description': 'Merkevarebygging var sentralt i vår arbeidsprosess for Affligem. Fra forskning og strategifaser til lansering av nettstedet sørget vi for å reflektere Affligems arv og pågående forpliktelse til å lage de fineste ølene med vår utvikling.'
                },
                'project16': {
                    'name': 'Zukunf Läuft',
                    'technologies': 'Phonegap, Cordova, Angular.JS, Ionic',
                    'description': 'Denne appen er basert på et vitenskapelig spørreskjema som hjelper studenter med å håndtere forventningene og talentene sine med det endelige målet om å finne den karrieren som passer best for dem. Vi kodet med fokus på klarhet, spesielt på hvordan informasjonen presenteres for brukeren.'
                },
                'project17': {
                    'name': 'BraveZone',
                    'technologies': 'Drupal 7, HTML, CSS, JavaScript',
                    'description': 'Vi samarbeidet tett med BraveZone-teamet for å utvikle nettstedporteføljen deres. De er en av de ledende reklamebyråene i Sverige, og det var en flott opplevelse å samarbeide med designerne og de kreative for å levere en modig, ny portefølje.'
                },
                'project18': {
                    'name': 'Médecins Sans Frontières',
                    'technologies': 'XML, PHP, MySQL, Apache, JavaScript',
                    'description': 'I løpet av et arbeidsforhold med MSF som har vært i årevis og sett oss fullføre en rekke utfordrende Drupal-prosjekter, har våre erfarne konsulenter hjulpet med å bryte ned digitale grenser for `Leger uten grenser`'
                },
                'project19': {
                    'name': 'GASAG',
                    'technologies': 'MVC 5, Sitecore 9.1.0, EXM (Integration with SAP), Multisite, Continues Integration - TeamCity, Octopus Deploy, HTML/HTML5, JavaScript, SCSS/CSS, Vue.JS, Gulp, Require.JS, JQuery, Node.JS for StoryBook, RestAPI, Docker',
                    'description': 'GASAG-gruppen har 15 nettsteder og portaler. De betjener både forbruker- og bedriftsbehov. GASAG er ett av de ledende og eldste selskapene som opererer i energisektoren i DE og spesielt i Berlin. Selskapet gjorde en komplett omstart av nettstedet til hovedfirmaet i 2020 og jobber for tiden med resten av portalene og nettstedene.'
                },
                'project20': {
                    'name': 'MassUp',
                    'technologies': 'PHP, MySQL, Magento 2, HTML5, CSS3, jQuery, React.JS, JavaScript, LESS',
                    'description': 'Dette oppstartsselskapet trengte vår utvikling for å lansere sin nye virksomhet til verden; det er en ny type digital MGA, som gjør det mulig for forhandlere, tjenesteleverandører, distributører og meglere å selge forsikring online. Vårt arbeid med den stadig voksende Massup fortsetter i dag.'
                },
                'project21': {
                    'name': 'HMD',
                    'technologies': 'GraphQL, Typescript, React, Next.js, Node.js, Emotion, Contentful, Azure, Akamai, Varnish, Nginx, Hotjar, Open Graph, Web-vitals, Module Federation, Google Analytics, Google Optimize',
                    'description': 'HMD Global er et uavhengig finsk selskap, den eneste store europeiske smarttelefonleverandøren som distribuerer Nokia-telefoner og -tabletter til verden. I tillegg til enheter og tilbehør tilbyr de en rekke tjenester for bedriftskunder. I tett samarbeid med det interne HMD-teamet hjalp Droxic-teamet med å redesigne og utvikle deres nye bedriftsnettsted, og tilknyttede tjenesteportaler for forbedret innhold, brukervennlighet, engasjement og potensial for generering av leads, med forbedret drift.'
                },
                'project22': {
                    'name': 'Siili',
                    'technologies': 'React.JS, JavaScript, TypeScript, Sitefinity, .NET',
                    'description': 'For several years now, Droxic has worked with Silli, a Finnish digital agency that collaborates with well-known brands in the marine, manufacturing, and various other industries. Our long-term partnership has developed complex web and mobile development solutions.'
                },
                'project23': {
                    'name': 'Open Talk',
                    'technologies': 'React Native, DevOps, React.JS, Flutter, Node.JS',
                    'description': 'Droxic has supported OpenTalk in developing and offering a SaaS solution for providers and other platforms, creating a POC and hybrid application, and implementing additional plugins to provide an optimized video platform solution and attract new users.'
                },
                'project24': {
                    'name': 'Fitogram',
                    'technologies': '.NET Core, React.JS',
                    'description': 'Droxic has participated in creating and developing an integrated platform that can be accessed and maintained easily on any device at any time. Our team has designed a complex microservice-based architecture to develop both the frontend (online booking of sports tools, videos on demand, and online courses) and the backend (video releases, attendance monitoring) of the platform. This has encouraged customer loyalty.'
                },
                'project25': {
                    'name': 'Quiet On',
                    'technologies': 'WordPress',
                    'description': 'We began supporting QuietOn with some modest tasks, including plugin updates and general maintenance. Recognizing difficulties with the backend, we overhauled it and switched to WPengine hosting. After the rebuild, we enhanced functionalities to boost sales, significantly improving performance and reducing maintenance costs. This results in providing greater value and a better user experience.'
                }
            },
            'news': {
                'detailsButtonLabel': 'View more',
                'frontPageTitle': 'News page title.',
                'frontPageDescription': 'Here we need a text for the News section.',

                'newsItem1': {
                    'title': 'Bees become harmful.',
                    'location': 'Berlin',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
                'newsItem2': {
                    'title': 'Immune cell tunnels.',
                    'location': 'Sofia',

                    'slideTitle1': 'Golden eagles face bright future.',
                    'slideTitleDescription1': 'The breakthrough will help conservationists understand how the bird’s DNA allows them to survive in various habitats, so they can make reintroductions and translocations more successful.',

                    'slideTitle2': 'Female monkeys don\'t trust males.',
                    'slideTitleDescription2': 'Female monkeys are reluctant to follow the example of males even when they would obviously benefit from doing so, new research has found.',
                },
            }
        },
        'menu': {
            'about': 'Om oss',
            'work': 'Portefølje',
            'contact': 'Kontakt',
            'news': 'Views',
            'careers': 'Karriere',
            'blog': 'Blogg',
            'droxicOod': '© 2025 Droxic | ',
            'policy': 'Personvernregler',
            'logoTooltip': 'Gå til hjemmesiden',
            'openMenuTooltip': 'Åpne meny',
            'closeMenuTooltip': 'Lukk meny'
        },
        'privacy': {
            'text': 'Vi bruker cookies for å forbedre din opplevelse. Ved å fortsette å besøke dette nettstedet samtykker du til vår bruk av informasjonskapsler. <a>Mer informasjon</a>',
            'buttonText': 'Forstått!',
            'page': {
                content: `
                    <h1 class="page-content-common-heading">DATABESKYTTELSE OG REGLER FOR BRUK AV COOKIES.</h1>

                    <div class="page-content-common-wrapper">
                        <section>
                            <p>Siste oppdatering: 25. mai 2018 / med hensyn til generell forordning for databeskyttelse.</p>
                            <p>Siste oppdatering: 25. mai 2018 / med hensyn til generell forordning for databeskyttelse.
Denne politikken for databeskyttelse og cookies gir generell informasjon om oss og vår personvernpolitikk og bruk av cookies på droxic.com ("nettsted"/"web side").</p>
                        </section>

                        <section>
                            <h2>OM DROXIC LTD.</h2>
                            <p>Vi er Droxic Ltd., et bulgarsk selskap, med UIC (Bulgarsk forretnings-ID): BG202224741 og registrert adresse i: Sofia, 62B Rodopski Izvor Str. etg.1 leil.4 ("Droxic"). Vi forstår og setter pris på betydningen av dine personopplysninger og dens beskyttelse som en del av ditt privatliv. Vi vil beskytte integriteten av dine personopplysninger ordentlig, enten det gjelder deg selvstendig eller i forhold til ditt selskap, tjenester eller produkter.</p>
                            <p>Ikke nøl med å kontakte oss angående dine personopplysninger på: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>

                        <section>
                            <h2>SAMLER VI INN PERSONOPPLYSNINGENE DINE VIA NETTSTEDET VÅRT?</h2>
                            <p>Generelt er det å samle inn noen personopplysninger mens vi bruker nettstedet vårt ikke et mål. Vi har ikke et kontaktskjema, og vi har ingen registreringsskjemaer. Kontaktdetaljene dine utgjør personopplysninger, derfor har vi bare listet opp våre kontaktdetaljer uten å be deg om spesifikke personopplysninger (som navn, e-post, telefonnummer osv.). Derfor er informasjonen du vil dele med oss avhengig av deg, og hvis du vil komme i kontakt så er det utelukkende av din beslutning, og vi vil behandle den med det spesifikke formål å adressere din forespørsel og kunne svare.</p>
                            <p>Vi skal bare bruke dine personopplysninger for vår korrespondanse, og den vil bli ugjenkallelig slettet etter at ingen ytterligere svar forventes fra noen side.</p>
                        </section>

                        <section>
                            <h2>BRUKER VI COOKIES?</h2>
                            <p>En cookie (informasjonskapsel) er en liten tekstfil som et nettsted lagrer på datamaskinen eller den mobile enheten din når du besøker nettstedet vårt. Droxic kan samle informasjon gjennom filer som informasjonskapsler eller loggfiler om nettlesingsvanene. Informasjonskapsler er filer fra nettstedet og lagres på brukerens nettleser via en webserver for vedlikehold av nettlesingssesjonen ved å lagre IP-adressen til brukeren (av hans eller hennes datamaskin) og andre mulige nettlesingsdata.</p>
                            <p>Droxic bruker kun webanalyse-cookies. Vi bruker dem for å måle og analysere brukerens nettlesing på <a href="http://www.droxic.com">www.droxic.com</a>. Analytiske informasjonskapsler lar oss overvåke og analysere brukerens oppførsel på nettstedet vårt. Webanalyse tillater ikke informasjon som navnene dine eller e-postadressen for å bli innhentet. Den eneste informasjonen som innhentes, er relatert til antall brukere som får tilgang til nettstedet, antall sider som er sett, frekvensen og repetisjonen av besøkene, deres varighet, nettleseren som brukes, operatøren som leverer tjenesten, språket, enheten som brukes, eller byen IP-en er tildelt.</p>
                            <p>Droxic bruker de beskrevne cookies via Google Analytics (en tjeneste levert av Google Inc.). Google Analytics samler inn førsteparts cookies, som beskrevet ovenfor. Informasjonen som samles inn med disse cookies, sendes til Google og brukes til å evaluere hvordan nettsiden vår blir brukt (for statistikkformål). Vi vil ikke knytte noe innsamlede data med personlig identifiserende informasjon. Vi vil ikke koble eller forsøke å koble informasjon fra cookies med noen IP-adresse eller med identiteten til en datamaskinbruker. Utover analytiske og statistiske formål kan Droxic bruke cookies for annonsering for å levere annonser som vi tror er mer relevante for deg og dine interesser. Droxic bruker tjenestene til Google, og du kan lese mer om retningslinjene deres her:</p>
                            <ul>
                                <li><p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">Google Analytics Cookie Usage on Websites</a></p></li>
                                <li><p><a href="https://policies.google.com/privacy">Privacy Policy – Privacy & Terms – Google</a></p></li>
                            </ul>
                            <p>Du kan kontrollere eller slette cookies hvis du ønsker gjennom alternativene i nettleseren din. Du kan slette alle cookies som allerede er på datamaskinen din, og du kan sette de fleste nettlesere til å forhindre at de blir plassert. Vær oppmerksom på at ved å gjøre dette kan det forhindre deg i å dra full nytte av nettsiden.</p>
                        </section>
                        
                        <section>
                            <h2>HAR VI RETT TIL Å BEHANDLE PERSONOPPLYSNINGER?</h2>
                            <p>Ja, det har vi, og vi følger strengt reglene i Generell forordning for  databeskyttelse når vi behandler personopplysninger. Oftest behandler vi personopplysninger på følgende juridiske grunnlag:</p>
                            <ul>
                                <li><p>Vi behandler det bare fordi vi er juridisk forpliktet til det - når behandlingen er nødvendig for overholdelse av en juridisk forpliktelse;</p></li>
                                <li><p>Vi behandler det bare fordi vi ikke kan oppfylle våre kontraktuelle forpliktelser uten å behandle det;</p></li>
                                <li><p>Vi behandler det for formålene med legitime interesser forfulgt av Droxic (for eksempel markedsføring).</p></li>
                                <li><p>Vi behandler det når den registrerte har gitt oss hans/hennes samtykke til å behandle det.</p></li>
                            </ul>
                            <p>Vi kan også behandle personopplysninger ved en annen anledning, men bare når behandlingen er tillatt av de spesifikke juridiske grunnlagene, som er fastsatt i Generell forordning for databeskyttelse.</p>
                            <p>Noen ganger kan vi ha tilgang til personopplysninger som er samlet inn av kundene våre (personopplysningskontrollerne). Hvis Droxic handler i kapasitet av personopplysningsbehandler, skal Droxic alltid følge behandlingsinstruksjonene gitt av kunden og også forpliktelsene i henhold til Generell databeskyttelsesforordning.</p>
                        </section>
                        
                        <section>
                            <h2>HVORDAN BEHANDLER VI PERSONOPPLYSNINGER FRA VÅRE JOBBSØKERE ("KARRIERE"-SEKSJONEN)?</h2>
                            <p>Ved å sende inn dine personopplysninger samtykker du til at DROXIC holder og bruker dem i samsvar med regler for databeskyttelse og cookies. Vi samler inn og behandler informasjonen du gir når du søker på en jobb. Slike personlige detaljer inneholder vanligvis: ditt navn, din adresse, din e-post, din fødselsdato, kvalifikasjoner, arbeidshistorikk og i grunn alt annet du har valgt å inkludere.</p>
                            <p>Vi kan også søke etter annen offentlig tilgjengelig informasjon om deg som du har publisert på internett (f.eks. via linkedin.com).</p>
                            <p>Vi baserer oss på juridisk grunnlag for å behandle personopplysninger fra jobbsøkere - både vår legitime interesse for å gjennomføre rekrutteringsprosessen vår, og vi behandler det også for å ta skritt på forespørsel fra den registrerte før inngåelse av en kontrakt (arbeidsavtale).</p>
                            <p>Vi bruker dine personopplysninger for å vurdere søknaden din for rollen du har søkt på, og også for å vurdere den med tanke på fremtidige ledige stillinger hos Droxic. Derfor kan vi oppbevare dine personopplysninger i opptil 6 måneder etter at vi mottok dem.</p>
                            <p>Vær oppmerksom på at du når som helst kan be om at vi sletter dine personopplysninger (vanligvis inkludert i din CV/søknadsbrev), og vi vil alltid etterkomme forespørselen din, med mindre du allerede er ansatt hos Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HVORDAN BEHANDLER VI PERSONOPPLYSNINGER FRA <a href="http://www.linkedin.com">LINKEDIN.COM</a></h2>
                            <p>Våre HR-rekrutterere bruker sosiale plattformen <a href="http://www.linkedin.com">linkedin.com</a> for å bygge en talentpool i rekrutteringsprosessen for nåværende åpne stillinger og fremtidige stillinger hos Droxic. Vi kan bare behandle offentlig tilgjengelig informasjon som du har postet/lastet opp: ditt navn, din arbeidserfaring, utdanning, ferdigheter og sertifiseringer, prestasjoner, kontaktinformasjon (e-post eller link til LinkedIn-profil).</p>
                            <p>Hvis vi vurderer deg som kvalifisert og passende for vårt team, kan Droxic lagre personopplysningene du har listet opp på LinkedIn-profilen din. Vi kan lagre slik informasjon ved hjelp av Droxic's interne systemer eller via tredjepartsprogramvare (nemlig: <a href="http://www.podio.com">podio.com</a>, en skybasert tjeneste eid og administrert av Citrix Systems Inc.: <a href="https://www.citrix.com/about/legal/privacy/">https://www.citrix.com/about/legal/privacy/</a> ). Slike personopplysninger kan bli lagret av Droxic i opptil 3 år etter at vi har mottatt dem.</p>
                            <p>Hver "passive kandidat" som vi vurderer som passende blir kontaktet av Droxic og ordetlig informert om behandlingen av hans/hennes personopplysninger. Vi respekterer alles personvern, og hvis du foretrekker at vi slutter å behandle personopplysningene hentet fra <a href="http://linkedin.com">linkedin.com</a> eller vil at vi skal stoppe å kontakte deg, vennligst informer Droxic's rekrutterer som har kontaktet deg eller skriv til oss på: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> Hvis du ber oss om å slette dine personopplysninger, vil vi alltid etterkomme forespørselen din, med mindre du allerede er ansatt hos Droxic.</p>
                        </section>
                        
                        <section>
                            <h2>HAR DU MOTTATT ET BREV ELLER EN TELEFONSAMTALE FRA OSS SOM DU ANSER SOM "DIREKTE MARKEDSFØRING"?</h2>
                            <p>Vi kan i noen tilfeller behandle personopplysninger for direkte markedsføringsformål. Slik behandling er enten basert på vår legitime interesse for å gjøre det, eller den er basert på det uttrykkelige samtykket fra den registrerte. Hvis du ikke ønsker at vi skal fortsette å kontakte deg, kan du gjerne protestere mot denne typen behandling ved å skrive til: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a> eller ganske enkelt ved å klikke på "unsubscribe"-linken. Vi vil alltid etterkomme slike forespørsler!</p>
                        </section>
                        
                        <section>
                            <h2>HVORDAN BESKYTTER VI PERSONOPPLYSNINGER SOM VÅRE KUNDER GIR OSS?</h2>
                            <p>Vi er forpliktet til å sikre at personopplysningene våre kunder gir oss er sikre. For å forhindre uautorisert tilgang eller offentliggjøring har vi satt i gang egnede fysiske, elektroniske og administrative prosedyrer for å sikre og beskytte informasjonen vi samler inn - både personopplysninger og forretningshemmeligheter.</p>
                            <p>Med hensyn til art. 28 i Generell forordning for databeskyttelse ber vi om at en eksplisitt databehandleravtale blir signert. Vi er bestemt på å gi tilstrekkelige garantier for å iverksette passende tekniske og organisatoriske tiltak på en slik måte at behandlingen vil oppfylle kravene i denne forordningen og sikre beskyttelse av rettighetene til den registrerte. Disse beskyttelsestiltakene vil variere avhengig av sensitiviteten, formatet, plasseringen, mengden, distribusjonen og lagringen av personopplysningene, og inkluderer tiltak designet for å holde personopplysninger beskyttet mot uautorisert tilgang.</p>
                        </section>
                        
                        <section>
                            <h2>HVOR LENGE OPPBEVARER VI PERSONOPPLYSNINGENE?</h2>
                            <p>Oppbevaringstiden for personopplysningene avhenger av informasjonstypen, formålene den brukes til og andre faktorer.</p>
                            <p>Generelt vil vi oppbevare dine personopplysninger så lenge det med rimelighet er nødvendig for å oppfylle formålene de ble behandlet for, og ikke lenger enn det (med mindre loven krever eller tillater en lengre oppbevaringsperiode). Vi vil også beholde og bruke informasjonen din så lenge det er nødvendig for å løse tvister og/eller håndheve våre rettigheter og avtaler.</p>
                        </section>
                        
                        <section>
                            <h2>HVILKE RETTIGHETER HAR DU?</h2>
                            <p>Generell forordning for databeskyttelse gir deg mange rettigheter når det gjelder dine personopplysninger: rett til innsyn, korrigering, sletting, begrensning av behandling, innsigelse og dataportabilitet, be om en kopi av dine personopplysninger, eller hvis du ikke er fornøyd - du kan til og med klage til Databeskyttelsesmyndigheten - https://www.cpc.bg/. Vi vil ikke kreve at du betaler noen avgift for å få tilgang til dine personopplysninger eller for å utøve noen av de andre rettighetene, men vi kan kreve en rimelig avgift hvis forespørselen/ forespørslene dine er klart grunnløse eller overdrevne.</p>
                            <p>Spørsmål eller forespørsler relatert til denne policyen bør rettes til: <a href="mailto:privacy@droxic.com" title="Mail us">privacy@droxic.com</a></p>
                        </section>
                        
                        <section>
                            <h2>ENDRINGER I REGLER FOR DATABESKYTTELSE OG COOKIES</h2>
                            <p>Droxic forbeholder seg retten til å endre og oppdatere disse reglene når som helst. Vi kan også varsle de registrerte eller våre kunder i tilfelle vi foretar vesentlige oppdateringer.</p>
                        </section>
                    </div>
                `
            }
        }
    },
}